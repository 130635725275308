import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DocumentsService } from 'src/app/core/services/documents.service';
import { Router } from '@angular/router';
import { ROUTES } from '@core-app/enums';
import { RequestService } from '@core-app/services/request.service';
const { PROCEDURE_FILE_MANAGER_URI } = environment;

import {environment} from "../../../../../../environments/environment";

@Component({
  selector: 'app-validator-tab',
  templateUrl: './validator-tab.component.html',
  styleUrls: ['./validator-tab.component.scss']
})
export class ValidatorTabComponent implements OnInit {

  flagTap:any = null;
  
  urlSafeDocument: SafeResourceUrl;

  isLoading:boolean = true;

  /**
   * Ver documento
   */
  viewDocument:boolean = false;

  public selectOptionFilterManagementForm: FormGroup;

  public selectOptionFilterTrainingsFollowUpForm: FormGroup;

   /**
   * datos de la bandeja del validador
   */

   public dataTableManagement: any = [];

   public dataFilterTableManagement: any = [];

      /**
   * datos de la bandeja del validador
   */
   public searchbar:boolean=true;
  public datebar:boolean=false;

  public searchbarcapacitation:boolean=true;
  public datebarcapacitation:boolean=false;
  public statusbarcapacitation:boolean=false;
    public dataTableTrainingsFollowUp: any = [];

    public dataFilterTableTrainingsFollowUp: any = [];

   /**
    * Columnas de la bandeja para la pestaña de gestión del validador
    */

   public structureTableManagement: any= [
    {
      name: 'ID solicitud',
      type: 'texto',
      value: 'vcRadicado'
    },
    {
      name: 'Solicitante',
      type: 'texto',
      value: 'vcNombreUsuario'
    },
    {
      name: 'No. identificación',
      type: 'texto',
      value: 'intNumeroIdentificacionUsuario'
    },
   {
      name: 'Tipo de solicitud',
      type: 'texto',
      value: 'vcNombre'//vcNombre
    },
    {
      name: 'Tipo de solicitante',
      type: 'texto',
      value: 'vcTipoSolicitante'
    },
    {
      name: 'Fecha de solicitud',
      type: 'texto',
      value: 'dtFechaSolicitud'
    },
    {
      name: 'Estado de la solicitud',
      type: 'texto',
      value: 'vcTipoEstado'
    },
    {
      name: 'Acciones permitidas',
      type: 'enlace',
      value: 'Ver detalle'
    },
    {
      name: 'Tiempo de atención restante',
      type: 'remainingDays',
      value1: 'diasRestantes',
      value2: 'diasHabiles',
      color1: '#e6effd',
      color2: '#ff574d'
    }
   ];

   /**
    * Columnas de la bandeja para la pestaña de seguimiento de capacitaciones del validador
    */


   public structureTableTrainingsFollowUp: any= [
    {
      name: 'Número de resolución',
      type: 'texto',
      value: 'intNumeroResolucion'
    },
    {
      name: 'Nombre del ciudadano/ Entidad',
      type: 'texto',
      value: 'nombreCiudadanoEntidad'
    },
    {
      name: 'Identificación',
      type: 'texto',
      value: 'intNumeroIdentificacion'
    },
    {
      name: 'Fecha de resolución',
      type: 'texto',
      value: 'dtFechaResolucion'
    },
    {
      name: 'Estado de autorización',
      type: 'texto',
      value: 'blEstado'
    },
    {
      name: 'Documento',
      type: 'documento'
    },
    {
      name: 'Acciones permitidas',
      type: 'enlace',
      value: 'Ver capacitación',
      flag: "link"
    }
   ];


   constructor(private fb:FormBuilder, private documentService: DocumentsService,
    private sanitizer: DomSanitizer, private router: Router, private requestService: RequestService){

    this.isLoading = true;

    this.selectOptionFilterManagementForm = this.fb.group({
      selectOptionFilterInputManagement: ['0'],
      fecha:[],

      valueFilterInputManagement: ['', [Validators.pattern('[a-zA-Zóé0-9./ ]*'), Validators.maxLength(50)]]
    });

    this.selectOptionFilterTrainingsFollowUpForm = this.fb.group({
      selectOptionFilterInputTrainingsFollowUp: ['0'],
      fechacapacitation:[],
      estadocapacitation:[],
      valueFilterInputTrainingsFollowUp: ['', [Validators.pattern('[a-zA-Zóé0-9./ ]*'), Validators.maxLength(50)]]
    });

    this.flagTap = JSON.parse(localStorage.getItem('browserTab'));

    if(this.getTab(this.flagTap)){
      this.inTrainingsFollowUp();
      localStorage.setItem("browserTab", "null");
    }


    this.searchInManagement();

    //this.dataFilterTableTrainingsFollowUp = [];

    //console.log(this.dataFilterTableTrainingsFollowUp);

   }

   ngOnInit() {

  }
  changestatus(){
     console.log(this.selectOptionFilterManagementForm.get('selectOptionFilterInputManagement').value);
    if(this.selectOptionFilterManagementForm.get('selectOptionFilterInputManagement').value=="6")
    {
      this.datebar=true;
      this.searchbar =false;
    }
    else {
      this.datebar=false;
      this.searchbar =true;
    }
  }
  reports(){
    this.router.navigate([`aut-cap/request/generalReports`]);
  }

   redirectToViewDetail(e:any){
    console.log("Record to redirect to view detail");
    console.log(e);
    localStorage.setItem('Rol',"validador");
    this.router.navigateByUrl(`${ROUTES.MainPage}/${ROUTES.Dashboard}/${ROUTES.REQUEST_MANAGEMENT_VALIDATOR}/${e.record.idSolicitud}/${e.record.vcTipoSolicitante}`);
  }

  viewTraining(e:any){
    console.log("Ver capacitación");
    console.log(e);
localStorage.setItem('bandeja','validador');
   localStorage.setItem('idSolicitud',e.record.idSolicitud);
    this.router.navigate([`aut-cap/request/editar-capacitacion/${e.record.idCapacitacion}`]);
  }

  getTab(e:any):boolean{
    if (e !=null) {
      return true;
    }else{
      return false;
    }
  }

  viewApprovalDocument = (e:any) =>{
    console.log("Ver documento");
    console.log(e);
      this.isLoading=true;
      this.requestService.GetRequest(e.record.idSolicitud).subscribe((values) => {
      const filenumber=values.data.vcRadicado;
      const contenedor = "manipalimentos";
      this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl(`${PROCEDURE_FILE_MANAGER_URI}/Storage/GetBlob/${contenedor}/${filenumber}/${e.record.vcPath}`);
      this.isLoading=false;
      this.viewDocument = true;
    });

  }

  /**
   * Filtro de pestaña de gestión
   */

  filterManagement(){
    console.log('Cantidad de datos: '+this.dataFilterTableManagement.length);
    console.log(this.dataFilterTableManagement);
    console.log(this.selectOptionFilterManagementForm.get('selectOptionFilterInputManagement').value);
    switch (this.selectOptionFilterManagementForm.get('selectOptionFilterInputManagement').value) {
      case '1':
      this.dataFilterTableManagement = this.dataTableManagement.filter((d: { vcRadicado: any; }) => d.vcRadicado .includes(this.selectOptionFilterManagementForm.get('valueFilterInputManagement').value));
      break;
      case '2':
        this.dataFilterTableManagement = this.dataTableManagement.filter((d: { vcNombreUsuario: any; }) => d.vcNombreUsuario .includes( this.selectOptionFilterManagementForm.get('valueFilterInputManagement').value));
      break;
      case '3':
        this.dataFilterTableManagement = this.dataTableManagement.filter((d: { intNumeroIdentificacionUsuario: any; }) => d.intNumeroIdentificacionUsuario.toString() .includes( this.selectOptionFilterManagementForm.get('valueFilterInputManagement').value));
      break;
      case '4':
        this.dataFilterTableManagement = this.dataTableManagement.filter((d: { vcNombre: any; }) => d.vcNombre .includes( this.selectOptionFilterManagementForm.get('valueFilterInputManagement').value));
      break;
      case '5':
      this.dataFilterTableManagement = this.dataTableManagement.filter((d: { vcTipoSolicitante: any; }) => d.vcTipoSolicitante .includes( this.selectOptionFilterManagementForm.get('valueFilterInputManagement').value));
      break;
      case '6':
        console.log(this.selectOptionFilterManagementForm.get('fecha').value);
        console.log(this.selectOptionFilterManagementForm.get('fecha'));
        let fecha="";
        if(this.selectOptionFilterManagementForm.get('fecha').value!=null)
        {
          fecha=this.selectOptionFilterManagementForm.get('fecha').value.toString().split('-')
          fecha=fecha[2]+"/"+fecha[1]+"/"+fecha[0]
        }
        this.dataFilterTableManagement = this.dataTableManagement.filter((d: { dtFechaSolicitud: any; }) => d.dtFechaSolicitud == fecha);
      break;
      case '7':
        this.dataFilterTableManagement = this.dataTableManagement.filter((d: { vcTipoEstado: any; }) => d.vcTipoEstado .includes( this.selectOptionFilterManagementForm.get('valueFilterInputManagement').value));
      break;
      default:
        break;
    }
    console.log('Resultados filtrados');
    console.log('Cantidad de datos filtrados: '+this.dataFilterTableManagement.length);
    console.log(this.dataFilterTableManagement);
  }


  changestatuscapacitation(){

    if(this.selectOptionFilterTrainingsFollowUpForm.get('selectOptionFilterInputTrainingsFollowUp').value=="4")
    {
      this.datebarcapacitation=true;
      this.searchbarcapacitation =false;
      this.statusbarcapacitation =false;
    }
    else {
      if(this.selectOptionFilterTrainingsFollowUpForm.get('selectOptionFilterInputTrainingsFollowUp').value=="5")
      {
        this.datebarcapacitation=false;
        this.searchbarcapacitation =false;
        this.statusbarcapacitation =true;
      }
      else {
        this.datebarcapacitation=false;
        this.searchbarcapacitation =true;
        this.statusbarcapacitation =false;
      }

    }
  }

  /**
   * Filtro de pestaña de seguimiento de capacitaciones
   */

  filterTrainingsFollowUp(){
    console.log('Cantidad de datos: '+this.dataFilterTableTrainingsFollowUp.length);
    console.log(this.dataFilterTableTrainingsFollowUp);

    switch (this.selectOptionFilterTrainingsFollowUpForm.get('selectOptionFilterInputTrainingsFollowUp').value) {
      case '1':
        console.log(this.selectOptionFilterTrainingsFollowUpForm.get('valueFilterInputTrainingsFollowUp').value);
        console.log(this.dataFilterTableTrainingsFollowUp);
      this.dataFilterTableTrainingsFollowUp = this.dataTableTrainingsFollowUp.filter((d: { intNumeroResolucion: any; }) => d.intNumeroResolucion.includes(this.selectOptionFilterTrainingsFollowUpForm.get('valueFilterInputTrainingsFollowUp').value));
      break;
      case '2':
        this.dataFilterTableTrainingsFollowUp = this.dataTableTrainingsFollowUp.filter((d: { nombreCiudadanoEntidad: any; }) => d.nombreCiudadanoEntidad.includes(this.selectOptionFilterTrainingsFollowUpForm.get('valueFilterInputTrainingsFollowUp').value));
      break;
      case '3':
        this.dataFilterTableTrainingsFollowUp = this.dataTableTrainingsFollowUp.filter((d: { intNumeroIdentificacion: any; }) => d.intNumeroIdentificacion.toString().includes(this.selectOptionFilterTrainingsFollowUpForm.get('valueFilterInputTrainingsFollowUp').value));
      break;
      case '4':
        console.log(this.selectOptionFilterTrainingsFollowUpForm.get('fechacapacitation'));
        let fecha="";
        if(this.selectOptionFilterTrainingsFollowUpForm.get('fechacapacitation').value!=null)
        {
          fecha=this.selectOptionFilterTrainingsFollowUpForm.get('fechacapacitation').value.toString().split('-')
          fecha=fecha[2]+"/"+fecha[1]+"/"+fecha[0]
        }
        this.dataFilterTableTrainingsFollowUp = this.dataTableTrainingsFollowUp.filter((d: { dtFechaResolucion: any; }) => d.dtFechaResolucion == fecha);
        break;
      case '5':
        let status=this.selectOptionFilterTrainingsFollowUpForm.get('estadocapacitation').value;
        this.dataFilterTableTrainingsFollowUp = this.dataTableTrainingsFollowUp.filter((d: { blEstado: any; }) => d.blEstado == status);
        break;
      default:
        break;
    }
    console.log('Resultados filtrados');
    console.log('Cantidad de datos filtrados: '+this.dataFilterTableTrainingsFollowUp.length);
    console.log(this.dataFilterTableTrainingsFollowUp);
  }

  searchInManagement = () =>{
    const idUser = JSON.parse(localStorage.getItem('currentUser')).userId;

    let pos:any[] = [
      {
        id:1,
        estado: 'En Revisión'
      },
      {
        id:2,
        estado: 'En revisión - recurso'
      },
      {
        id:3,
        estado: 'Devuelta por coordinador'
      },
      {
        id:4,
        estado: 'Vencimiento de términos'
      },
      {
        id:5,
        estado: 'Aprobado'
      },
    ]

    this.requestService.GetAllRequestsValidator(idUser).subscribe((result) => {
      console.log("----datos----");
      console.log(result);
      console.log("id present", idUser);
      if(result.data !=null){

        result.data.forEach((e:any) => {

          if(e.vcTipoEstado == "En Revisión" && e.resultadoValidacionId == 11){
            e.vcTipoEstado = "En revisión - recurso"
          }

          let isNatural:boolean = e.vcTipoSolicitante == 'P. Natural';
          console.log(isNatural?'natural':'juridica');

          const [day, month, year] = e.dtFechaSolicitud.split('/');
          let date = new Date(year+"-"+month+"-"+day);
          date.setDate(date.getDate() + 1);
          let fecha = date.getFullYear() + "-"+ (date.getMonth()+1).toString().padStart(2,'0') + "-" + date.getDate().toString().padStart(2,'0');
          this.requestService.getRemainingDays('2CB35A45-12D8-4AAB-8A01-5DC5FDDAC591', fecha).subscribe((rd) => {
            e.diasRestantes = isNatural?rd.data.personaNatural.diasRestantes:rd.data.personaJuridica.diasRestantes;
            e.diasHabiles = isNatural?rd.data.personaNatural.totalANS:rd.data.personaJuridica.totalANS;
          });
          e.pos = pos.find(x => e.vcTipoEstado == x.estado).id != undefined?pos.find(x => e.vcTipoEstado == x.estado).id:5;
         // console.log( pos.find(x => e.vcTipoEstado == x.estado));
        });
        this.dataTableManagement = result.data.sort((a: any,b: any) => {
          console.log(a,b);
          return a.pos - b.pos;
        });
        this.dataFilterTableManagement = this.dataTableManagement;
      };
      this.isLoading = false;
      /*
      console.log(this.dataTableManagement.sort((a: any,b: any) => {
        console.log(a,b);
        return a.pos - b.pos;
      }));
      */

    })
  }

  inManagement = () =>{
    this.selectOptionFilterManagementForm.get('selectOptionFilterInputManagement').setValue(0);
    // this.isLoading = true;
    //this.dataFilterTableManagement = this.dataTableManagement;
    this.isLoading = true;
    this.searchInManagement();
  }

  inTrainingsFollowUp = () =>{
    this.selectOptionFilterTrainingsFollowUpForm.get('selectOptionFilterInputTrainingsFollowUp').setValue(0);
    this.isLoading = true;
    this.requestService.getCapacitationdashboard().subscribe((values) => {
      console.log("datos en la solicitud", values);

      this.dataTableTrainingsFollowUp = values.data.map((item: any) => {
        if (item.blEstado === true) {
          item.blEstado = 'Activa';
          item.link = false;
        }else{
          item.blEstado = 'Inactiva';
          item.link = true;
        }
        console.log(item);
        return item;
      });
      const ids = values.data.map((obj: any) => obj.idSolicitud);
      console.log("Valores de idSolicitud:", ids);
      console.log("datos encontrados para filtrar", this.dataTableTrainingsFollowUp);
      this.dataFilterTableTrainingsFollowUp =this.dataTableTrainingsFollowUp;
      this.isLoading = false;
    });
  }

  closeDocument = () => {
    this.viewDocument = false;
  }

}
