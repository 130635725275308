import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {DocumentSupportDto} from "../models/documentSupportDto.model";
import { documentDto } from '@core-app/models/documentDto.model';
const { PROCEDURE_SHARED_URI } = environment;
const { PROCEDURE_SECURITY_URI } = environment;
const { PROCEDURE_NOTIFICATIONS_URI } = environment;
//const { PROCEDURE_LOCAL_URI } = environment;
const { PROCEDURE_FILE_MANAGER_URI } = environment;

/**
 * Service con los métodos relacionados al registro de una persona
 */
@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor(private http: HttpClient) { }


  /**
   * Obtiene los documentos de la solicitud
   * @param id
   */
  GetBlob(contenedor: string, oid: string, nameBlob: string) : Observable<any> {
    return this.http.get(`${PROCEDURE_FILE_MANAGER_URI}/Storage/GetBlob/${contenedor}/${oid}/${nameBlob}`, { responseType: 'blob', observe: 'response' });
  }

  /**
   * guarda los documentos de la solicitud
   * @param document
   */
  addBlob(document:FormData) : Observable<any> {
    return this.http.post(`${PROCEDURE_FILE_MANAGER_URI}/Storage/AddFile`,document);
  }


  /**
   * Obtiene los documentos de la solicitud
   * @param id
   */
  /*
  getDocumentsByIdRequest(id: string) : Observable<any> {
    return this.http.get(`${PROCEDURE_LOCAL_URI}/Document/GetDocumentsByid/${id}`);
  }
  */

  /**
   * Agrega los documentos a una solicitud
   * @param documents Documentos a guardar
   */
  /*
  public addDocumentsToRequest(documents: DocumentSupportDto[]): Observable<any> {
    return this.http.post(`${PROCEDURE_LOCAL_URI}/Document/AddDocuments`, documents);
  }
  */

  convertBase64ToPdfFile(base64: string, filename: string): File {
    try {
      base64 = `data:application/pdf;base64,${base64}`;
      const base64Arr = base64.split(',');
      const mime = base64Arr[0].match(/:(.*?);/)[1];
      const bstr = atob(base64Arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    } catch (e) {
      console.log("se daño el base64", e)
      return null;
    }
  }


  /**
   * actualiza los documentos de la solicitud
   * @param rol
   */
  /*
  updateDocumentsByIdRequest(documents: any) : Observable<any> {
    return this.http.put(`${PROCEDURE_LOCAL_URI}/Document/UpdateDocuments`,documents);
  }
  */

  /**
   * Obtiene el pdf de la resolucion (preliminar o definitiva)
   * @param rol
   */
  /*
  getResolutionPdf(idrequest:string,
                   status:string,
                   rol:string,
                   ma:string,
                   maj:string,
                   maa:string,
                   preliminar:boolean
  ) : Observable<any> {
    return this.http.get(`${PROCEDURE_LOCAL_URI}/Document/GetGeneratedPDF/${idrequest}/${status}/${rol}/${ma}/${maj}/${maa}/${preliminar}`);
  }
  */


}
