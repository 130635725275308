import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { securityPolicyDto } from '@core-app/models/securityPolicyDto';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
//const {PROCEDURE_LOCAL_URI} = environment;

@Injectable({
  providedIn: 'root'
})
export class SecurityPolicyService {

  constructor(private http: HttpClient) { }

  /**
   * Agrega el registro de confirmación de la politica de seguridad en base de datos.
   * @param securityPolicy confirmación de politica de seguridad a guardar
   */
  /*
  public addSecurityPolicy(securityPolicy: securityPolicyDto): Observable<any> {
    return this.http.post(`${PROCEDURE_LOCAL_URI}/PoliticaSeguridad/AddPoliticaSeguridad`, securityPolicy);
  }
  */

  /**
   * Obtener el registro de confirmación de la politica de seguridad en base de datos.
   * @param securityPolicy confirmación de politica de seguridad a guardar
   */
  /*
  public getSecurityPolicy(id_usuario: string): Observable<any> {
    return this.http.get(`${PROCEDURE_LOCAL_URI}/PoliticaSeguridad/GetPoliticaSeguridad/${id_usuario}`);
  }
  */
}
