<div class="d-flex justify-content-end mr-5 reporte">
  <button type="button" class="button btn-primary" id="reports" (click)="reports()">REPORTES</button>
</div>

<div class="filtros mb-5">
  <form [formGroup]="selectOptionFilterForm">
      <div class="row">
          <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12 col-xl-12">
              <label class="d-block">Filtrar por</label>
          </div>
      </div>
      <div class="row">
          <div class="col-lg-3 col-sm-12 col-xs-12 col-xl-3 col-md-3 filtroSelect">
              <select formControlName="selectOptionFilterInput" class="form-control" aria-label="Seleccione" (change)="changestatus()">
                  <option value="0" hidden>Seleccione una opción</option>
                  <option value="1">ID solicitud</option>
                  <option value="2">Solicitante</option>
                  <option value="3">No. identificación</option>
                  <option value="4">Tipo de solicitud</option>
                  <option value="5">Tipo de solicitante</option>
                  <option value="6">Fecha de solicitud</option>
                  <option value="7">Estado de la solicitud</option>
              </select>
          </div>
        <div class="col-lg-3 col-sm-12 col-xs-12 col-xl-3 col-md-3" *ngIf="searchbar">
          <input formControlName="valueFilterInput" class="form-control search_input_icon" type="text" placeholder="Ingrese su búsqueda" />
        </div>
          <div class="col-lg-3 col-sm-12 col-xs-12 col-xl-3 col-md-3" *ngIf="datebar">
              <input formControlName="fecha" class="form-control search_input_icon" type="date"  />
          </div>
          <div class="col-lg-3 col-sm-12 col-xs-12 col-xl-3 col-md-3 bf">
              <button
                  class="btn btn-round btn-middle mx-3 botonFiltrar" id="send_filter"
                  style="padding: 10px 10px; width: 12%; font-size: 17px; padding: 5px 40px;"
                  [disabled]="selectOptionFilterForm.get('selectOptionFilterInput').value==0 || selectOptionFilterForm.get('valueFilterInput').status == 'INVALID'"
                  (click)="filter()"
                  type="button"
              >
                  FILTRAR
              </button>
          </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12 col-xl-12">
          <div *ngIf="selectOptionFilterForm.get('valueFilterInput').status=='INVALID'" class="alert mt-2"
          style="background-color: #e6effd;color: #004884;font-size: 15px; height: 100px;">
          <p> * Solo se admiten caracteres alfanuméricos, a excepción de los caracteres /, . y el - no ingrese caracteres especiales.</p>
          <p> * La cantidad máxima de caracteres admitidos es 50.</p>
        </div>
        </div>
      </div>
  </form>
</div>

<nz-spin style="display: block; width: 100%;" [nzSpinning]="isLoading" [nzSize]="'large'" nzTip="Se estan cargando los datos, por favor espere un momento....">
  <app-procedure-dashboard  [data]="dataFilterTableManagement" [structure]="structureTable" (linkEvent)="redirectToViewDetail($event)"></app-procedure-dashboard>
</nz-spin>
