import { emailDTO } from '@core-app/models/emailDto';
import { documentDto } from './../../../../../core/models/documentDto.model';
import { filter ,lastValueFrom, switchMap} from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { Usuario } from '@core-app/services/usuario';
import { ROUTES } from '../../../../../core/enums/routes.enum';
import { SharedService } from '@core-app/services/shared.service';
import { PersonService } from '@core-app/services/person.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RequestService } from '@core-app/services/request.service';
import { DocumentsService } from '@core-app/services/documents.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import {GoogleRecaptchaService} from "@core-app/services/google-recaptcha.service";
import { AppBaseComponent } from '@core-app/utils';

@Component({
  selector: 'app-citizen-form',
  templateUrl: './citizen-form.component.html',
  styleUrls: ['./citizen-form.component.scss'],
})
export class CitizenFormComponent extends AppBaseComponent implements OnInit {

  datosTrainers: any = [];

  documents: any = [
    {
      id: 1,
      name: 'Cédula de ciudadanía',
      mandatory: true,
      initials: 'cc'
    },
    {
      id: 2,
      name: 'Carta de solicitud',
      mandatory: true,
      initials: 'cs'
    },
    {
      id: 3,
      name: 'Hoja de vida',
      mandatory: true,
      initials: 'hv'
    },
    {
      id: 4,
      name: 'Descripción detallada del curso',
      mandatory: true,
      initials: 'dtc'
    },
    {
      id: 5,
      name: 'Pensum de pregrado',
      mandatory: false,
      initials: 'pp'
    },
    {
      id: 6,
      name: 'Diploma profesional / tecnólogo',
      mandatory: true,
      initials: 'dpt'
    },
    {
      id: 7,
      name: 'Tarjeta profesional',
      mandatory: true,
      initials: 'tp'
    },
    {
      id: 8,
      name: 'Resumen didáctico del curso',
      mandatory: true,
      initials: 'rdc'
    },
    {
      id: 9,
      name: 'Memorias ilustradas',
      mandatory: true,
      initials: 'mi'
    }
  ]

  advanceLineData = [
    {
      name: 'Inicio',
      link: 'aut-cap/'+ROUTES.Request+"/"+ROUTES.Menu
    },
    {
      name: 'Hago mi solicitud',
      link: 'aut-cap/'+ROUTES.Request+"/"+ROUTES.REQUEST_CITIZEN
    },
    {
      name: 'Procesan mi solicitud',
      link: ''
    },
    {
      name: 'Respuesta',
      link: 'aut-cap/'+ROUTES.Request+"/"+ROUTES.SearchRequest
    }
  ]

  isLoading:boolean = true;

  documentsArray: any = [];

  idDocumentType: number;

  idRequestType: number;

  documentsArrayPosition: any = [];

  urlSafeDocument: SafeResourceUrl;

  formRequest: FormGroup;

  documentsTypes: any = [];

  /**
   * Ver documento
   */
  viewDocument:boolean = false;
  mostrarPersonaJuridica: boolean = false;
  tiposCapacitacion: any[];
  selectdni:any[]=[];
  selecttypePerson: string = '';
  userData: any;
  userId: any;
  userInformation:any;
  constructor(
    private router: Router,
    private usuario: Usuario,
    private type: PersonService,
    private sanitizer: DomSanitizer,
    private fb:FormBuilder,
    private requestService: RequestService,
    private documentService: DocumentsService,
  private recaptchaService: ReCaptchaV3Service,
  private googleRecaptchaService: GoogleRecaptchaService
  ) {
    super();
    this.formRequest = this.fb.group({
      razon: [''],
      nit: [''],
      code: [''],
      primerNombre: [''],
      segundoNombre: [''],
      primerApellido: [''],
      segundoApellido: [''],
      tipoDocumento: [''],
      numeroDocumento: [''],
      numeroContactoN: [''],
      correoElectronicoN: [''],
      address: [''],
      titulo: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(50), Validators.pattern('[a-zA-Z ]*')]],
      tarjeta: ['', Validators.required]
    });

    /*

    Tipo de documento de soporte

    this.requestService.GetDocumentTypes('').subscribe((res) => {

    })
    */

    this.userId = JSON.parse(localStorage.getItem("currentUser")).userId;

    let user = JSON.parse(localStorage.getItem("currentUser"));

    this.usuario.getInfoperson(this.userId).subscribe((data: any) => {
      this.usuario.getTotalperson(data['data']).subscribe((valores: any) => {

        this.userInformation = valores.data;

        this.getIdRequestType();

        if (valores.data.numeroIdentificacionRL !=null && valores.data.numeroIdentificacionRL !=0) {
          this.mostrarPersonaJuridica = true;
          console.log(this.userInformation);
          this.getIdNumber(this.userInformation.tipoIdentificacionRL);
        }else{
          this.getIdNumber(this.userInformation.tipoIdentificacion);
          console.log(this.userInformation);

        }
        console.log(this.mostrarPersonaJuridica);
        this.isLoading = false;
      })
    })
  }

  ngOnInit(): void {
    this.getTiposCapacitacion();
    this.getTypePerson();
    this.getinfoPerson();
    this.listDni();
  }

  getTypePerson() {
    this.selecttypePerson = this.type.getSelecttypePerson();
    console.log('Valor de selecttypePerson en el componente de destino:', this.selecttypePerson);
    this.type.setSelecttypePerson(this.selecttypePerson);
  }

  getinfoPerson(){
    this.userData = this.type.getUserData();
    console.log("prueba component" , this.userData);
  }

  getTiposCapacitacion() {
    this.usuario.getTiposCapacitacion().subscribe((data) => {
      this.tiposCapacitacion = data.data;
      console.log(this.tiposCapacitacion);
    });
  }

  showCancelConfirmation() {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Si cancelas, perderás la información ingresada.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigateByUrl(
          ROUTES.MainPage + '/' + ROUTES.Request + '/' + ROUTES.Menu
        );
      }
    });
  }

  listDni(){
    this.usuario.getTypeDni('bTipoIdentificacion').subscribe((values)=>{
      this.selectdni = values.data;
      console.log("lista  citizen", this.selectdni);
    })
  }

  async enviarSolicitud() {

    console.log(this.idDocumentType);
    console.log(this.idRequestType);
    console.log(JSON.parse(localStorage.getItem("tiposCapacitaciones")));
    let respuestaGoogle: any;
    await lastValueFrom(this.recaptchaService.execute("importantAction").pipe(
      switchMap(token => this.googleRecaptchaService.verifyRecaptcha(token))
    )).then(resp => respuestaGoogle = resp);

    if (!respuestaGoogle.success) {
      console.log("Recaptcha no valido")

      return;
    }


    let capacitaciones = JSON.parse(localStorage.getItem("tiposCapacitaciones"));

    let data: any = null;

    // console.log(this.documentsArray);

    if (!this.mostrarPersonaJuridica) {

      if (this.formRequest.valid) {

        if (this.formRequest.get("titulo").value != '') {

          if (this.formRequest.get("tarjeta").value != '') {

            if (capacitaciones.length != 0) {

              let capacitadorTipoCapacitacionArray: any[] = [];

              let obj: any;

              capacitaciones.forEach((element: any) => {

                obj = {
                  idTipoCapacitacion: element.idTipoCapacitacion
                }
                capacitadorTipoCapacitacionArray.push(obj);
              });

              console.log(capacitadorTipoCapacitacionArray);

              console.log(this.documentsArray);

              let cantidadDocumentos: number = this.documentsArray.filter((d: any) => d.document.name != 'Pensum de pregrado').length;

              console.log(cantidadDocumentos);

              this.requestService.GetDocumentTypes("bDocumentosSolicitud").subscribe((res) => {
                console.log("tipo de docuumento soporte");
                console.log(res.data);

                if (cantidadDocumentos == 8) {

                  this.isLoading = true;

                  let documentoSolicitudArray: any[] = [];

                  let idDocumentType: any[] = [];

                  this.documentsArray.forEach((da: any) => {

                    idDocumentType = res.data.filter((d: any) => d.vcNombre == da.document.name);

                    let obj2 = {
                      idDocumento: 0,
                      solicitudId: 0,
                      usuarioId: this.userId,
                      tipoDocumentoId: idDocumentType.length == 0 ? 0 : idDocumentType[0].idParametroDetalle,
                      vcNombreDocumento: da.document.name,
                      vcPath: this.userInformation.numeroIdentificacion + "_" + (da.document.name.replaceAll("/", "_")).replaceAll(" ", "_") + ".pdf"
                    }

                    documentoSolicitudArray.push(obj2);
                  });

                  console.log(documentoSolicitudArray);

                  this.requestService.generateFileNumber({codigoTramite: "22"}).subscribe((fileNumber) => {
                    console.log(fileNumber);
                    data = {
                      idSolicitud: 0,
                      usuarioId: this.userId,
                      vcNombreUsuario: this.userInformation.primerNombre + " " + this.userInformation.primerApellido,
                      intNumeroIdentificacionUsuario: this.userInformation.numeroIdentificacion,
                      vcDireccionUsuario: this.userInformation.direResi,
                      tipoSolicitudId: this.idRequestType,
                      vcTipoSolicitante: "P. Natural",
                      vcRadicado: fileNumber.data.consecutivo,
                      capacitadorSolicitud: [
                        {
                          idCapacitadorSolicitud: "",
                          solicitudId: 0,
                          vcPrimerNombre: this.userInformation.primerNombre,
                          vcSegundoNombre: this.userInformation.segundoNombre,
                          vcPrimerApellido: this.userInformation.primerApellido,
                          vcSegundoApellido: this.userInformation.segundoApellido,
                          tipoIdentificacionId: this.idDocumentType,
                          intNumeroIdentificacion: this.userInformation.numeroIdentificacion,
                          vcTituloProfesional: this.formRequest.get("titulo").value,
                          vcNumeroTarjetaProfesional: this.formRequest.get("tarjeta").value,
                          intTelefono: this.userInformation.telefonoCelular,
                          vcEmail: this.userInformation.email,
                          capacitadorTipoCapacitacion: capacitadorTipoCapacitacionArray,
                          documentoSolicitud: documentoSolicitudArray
                        }
                      ]
                    };

                    this.usuario.recordNewPost(data).subscribe(
                      (response) => {

                        this.documentsArray.forEach((da: any) => {

                          const formData = new FormData();
                          const reader = new FileReader();
                          reader.onload = (e: any) => {
                            console.log(e.target.result);
                            formData.append("Oid", fileNumber.data.consecutivo);
                            formData.append("NameFile", this.userInformation.numeroIdentificacion + "_" + (da.document.name.replaceAll("/", "_")).replaceAll(" ", "_") + ".pdf");
                            formData.append("ContainerName", "manipalimentos");
                            formData.append("File", new Blob([new Uint8Array(e.target.result)], {type: "application/pdf"}));
                            this.documentService.addBlob(formData).subscribe((respuesta: any) => {
                              console.log(respuesta);
                            })
                          };
                          reader.readAsArrayBuffer(da.file);


                        });

                        this.requestService.GetTemplate(1).subscribe((res) => {

                          console.log("Envio de notificaciones");
                          console.log(res.data);

                          let plantilla: string = res.data.vcPlantilla;

                          plantilla = plantilla.replace("~:~nombre_del_solicitante~:~", this.userInformation.primerNombre + " " + this.userInformation.primerApellido);
                          plantilla = plantilla.replace("~:~numero_radicado~:~", fileNumber.data.consecutivo);

                          let email: emailDTO = {
                            to: this.userInformation.email,
                            body: plantilla,
                            subject: 'Radicación de solicitud de Autorización de capacitadores para manipulacion de alimentos'
                          }

                          this.requestService.sendNotification(email).subscribe((r) => {
                            console.log(r);
                          })
                        });


                        //falta guardar el seguimiento de la solicitud
                        console.log(response);
                        this.isLoading = false;
                        Swal.fire({
                          text: 'Solicitud guardada.',
                          icon: 'success',
                          showCancelButton: false,
                          confirmButtonColor: '#3085d6',
                          confirmButtonText: 'Aceptar',
                        }).then((result) => {
                          if (result.isConfirmed) {

                            let template = "";

                            this.requestService.GetDocumentTypes("bCorreoFuncionario").subscribe(res => {

                              let emailAdress = res.data.find((x: { vcNombre: string; }) => x.vcNombre == "validador" ).txDescripcion;
                
                
                              this.requestService.GetTemplate(19).subscribe((en:any) => {
                                console.log(en);
                                template = en.data.vcPlantilla.replaceAll("~:~body~:~","Tiene una nueva solicitud para su verificación del trámite 'Autorización para capacitadores de manipulación de alimentos'")
                  
                                let email: emailDTO = {
                                  to: emailAdress,
                                  body: template,
                                  subject: 'Solicitud para verificación | ID '+fileNumber.data.consecutivo+" | En Revisión"
                                }
                                this.requestService.sendNotification(email).subscribe((r) => {
                                  console.log(r);
                                })
                              });
                
                            });
                            this.router.navigateByUrl(`${ROUTES.MainPage}/${ROUTES.Request}/${ROUTES.Success}/${fileNumber.data.consecutivo}`);
                          }
                        });
                      }
                    );
                  });
                } else {
                  Swal.fire({
                    icon: 'error',
                    title: '<h5>Documentos obligatorios incompletos</h5>',
                    text: 'Faltan documentos obligatorios por cargar.',
                    allowOutsideClick: false
                  });
                }
              })
            } else {
              Swal.fire({
                icon: 'error',
                title: '<h5>Tipos de capacitaciones sin registrar</h5>',
                text: 'Debe registrar al menos un tipo de capacitación.',
                allowOutsideClick: false
              });
            }

          } else {
            Swal.fire({
              icon: 'error',
              title: '<h5>Número de tarjeta profesional sin registrar</h5>',
              text: 'Debe registrar el número de tarjeta profesional.',
              allowOutsideClick: false
            });
          }

        } else {
          Swal.fire({
            icon: 'error',
            title: '<h5>Titulo profesional sin registrar</h5>',
            text: 'Debe registrar el titulo profesional.',
            allowOutsideClick: false
          });
        }
      }else {

        console.log(super.getAllErrors(this.formRequest));
        Swal.fire({
          icon: 'error',
          title: '<h5>Campos digitados incorrectamente</h5>',
          text: 'Hay campos registrados incorrectamente.',
          allowOutsideClick: false
        });

      }

      console.log("hola mundo");

    } else {
      console.log("datos capacitador", JSON.parse(localStorage.getItem("datosTrainers")));
      let sendRequest: any[] = [];
      let bandera = JSON.parse(localStorage.getItem("datosTrainers")).length != 0;

      if (bandera) {

        this.isLoading = true;

        this.requestService.GetDocumentTypes("bDocumentosSolicitud").subscribe((res) => {
          console.log("tipo de docuumento soporte");
          console.log(res.data);


          this.datosTrainers.forEach((element: any) => {

            let documentoSolicitudArray: any[] = [];

            let idDocumentType: any[] = [];

            element.documents.forEach((da: any) => {

              idDocumentType = res.data.filter((d: any) => d.vcNombre == da.document.name);

              let obj2 = {
                idDocumento: 0,
                solicitudId: 0,
                usuarioId: this.userId,
                tipoDocumentoId: idDocumentType.length == 0 ? 0 : idDocumentType[0].idParametroDetalle,
                vcNombreDocumento: da.document.name,
                vcPath: element.document + "_" + (da.document.name.replaceAll("/", "_")).replaceAll(" ", "_") + ".pdf"
              }

              documentoSolicitudArray.push(obj2);
            });

            console.log(documentoSolicitudArray);

            let capacitaciones: any[] = [];
            let cp: any = null;

            element.capacitation.forEach((element2: any) => {
              cp = {
                idTipoCapacitacion: element2
              }
              capacitaciones.push(cp);
            });

            let obj: any = {
              idCapacitadorSolicitud: "",
              solicitudId: 0,
              vcPrimerNombre: element.firstname,
              vcSegundoNombre: element.secondname == undefined ? '' : element.secondname,
              vcPrimerApellido: element.flastname,
              vcSegundoApellido: element.slastname == undefined ? '' : element.slastname,
              tipoIdentificacionId: element.stypeidentity,
              intNumeroIdentificacion: element.document,
              vcTituloProfesional: element.title,
              vcNumeroTarjetaProfesional: element.numbertitle,
              intTelefono: element.contact,
              vcEmail: element.email,
              capacitadorTipoCapacitacion: capacitaciones,
              documentoSolicitud: documentoSolicitudArray
            }
            sendRequest.push(obj);
          });

          console.log("datos de trainers", sendRequest);

          this.requestService.generateFileNumber({codigoTramite: "22"}).subscribe((fileNumber) => {
            console.log(fileNumber);
            data = {
              idSolicitud: 0,
              usuarioId: this.userId,
              vcNombreUsuario: this.userInformation.primerNombre + " " + this.userInformation.primerApellido,
              intNumeroIdentificacionUsuario: this.userInformation.numeroIdentificacion,
              vcDireccionUsuario: this.userInformation.direResi != null?this.userInformation.direResi:"",
              tipoSolicitudId: this.idRequestType,
              vcTipoSolicitante: "P. Juridica",
              vcRadicado: fileNumber.data.consecutivo,
              capacitadorSolicitud: sendRequest
            };
            console.log("datos de la solicitud 2: ");
            console.log(data)


            this.usuario.recordNewPost(data).subscribe(
              (response) => {

                this.datosTrainers.forEach((element: any) => {

                  element.documents.forEach((da: any) => {

                    const formData = new FormData();
                    const reader = new FileReader();
                    reader.onload = (e: any) => {
                      console.log(e.target.result);
                      formData.append("Oid", fileNumber.data.consecutivo);
                      formData.append("NameFile", element.document + "_" + (da.document.name.replaceAll("/", "_")).replaceAll(" ", "_") + ".pdf");
                      formData.append("ContainerName", "manipalimentos");
                      formData.append("File", new Blob([new Uint8Array(e.target.result)], {type: "application/pdf"}));
                      this.documentService.addBlob(formData).subscribe((respuesta: any) => {
                        console.log(respuesta);
                      })
                    };
                    reader.readAsArrayBuffer(da.file);
                  });
                });
                this.requestService.GetTemplate(1).subscribe((res) => {

                  console.log("Envio de notificaciones");
                  console.log(res.data);

                  let plantilla: string = res.data.vcPlantilla;

                  plantilla = plantilla.replace("~:~nombre_del_solicitante~:~", this.userInformation.primerNombre + " " + this.userInformation.primerApellido);
                  plantilla = plantilla.replace("~:~numero_radicado~:~", fileNumber.data.consecutivo);

                  let email: emailDTO = {
                    to: this.userInformation.email,
                    body: plantilla,
                    subject: 'Radicación de solicitud de Autorización de capacitadores para manipulacion de alimentos'
                  }

                  this.requestService.sendNotification(email).subscribe((r) => {
                    console.log(r);
                  })
                });


                //falta guardar el seguimiento de la solicitud
                console.log(response);
                Swal.fire({
                  text: 'Solicitud guardada.',
                  icon: 'success',
                  showCancelButton: false,
                  confirmButtonColor: '#3085d6',
                  confirmButtonText: 'Aceptar',
                }).then((result) => {
                  if (result.isConfirmed) {

                    let template = "";

                    this.requestService.GetDocumentTypes("bCorreoFuncionario").subscribe(res => {

                      let emailAdress = res.data.find((x: { vcNombre: string; }) => x.vcNombre == "validador" ).txDescripcion;
                      
                      console.log(emailAdress);
        
                      this.requestService.GetTemplate(19).subscribe((en:any) => {
                        console.log(en);
                        template = en.data.vcPlantilla.replaceAll("~:~body~:~","Tiene una nueva solicitud para su verificación del trámite 'Autorización para capacitadores de manipulación de alimentos'")
          
                        let email: emailDTO = {
                          to: emailAdress,
                          body: template,
                          subject: 'Solicitud para verificación | ID '+fileNumber.data.consecutivo+" | En Revisión"
                        }
                        this.requestService.sendNotification(email).subscribe((r) => {
                          console.log(r);
                        })
                      });
        
                    });
                    this.router.navigateByUrl(`${ROUTES.MainPage}/${ROUTES.Request}/${ROUTES.Success}/${fileNumber.data.consecutivo}`);
                  }
                });
              }
            );
          });
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: '<h5>Capacitadores sin registrar</h5>',
          text: 'Debe registrar al menos un capacitador.',
          allowOutsideClick: false
        });
      }
    }


  }

  onChange = (e:any, d:any) => {
    console.log("este es un evento");
    console.log(e);


    if(e.file.target.files.length>0)
    {
      let posicion=-1;
      for (let i = 0; i <this.documentsArray.length ; i++) {
        if(this.documentsArray[i].document.id ==e.document.id)
        {
          posicion=i;

          this.documentsArray[i]={file: e.file.target.files[0], document: e.document}
          break;
        }

      }
      if(posicion==-1 || this.documentsArray.length==0)
      {
        this.documentsArray.push({file: e.file.target.files[0], document: e.document});
        this.documentsArrayPosition.push(d);
      }
      //console.log(this.documentsArray);
    }
    else {
      let p = this.documentsArrayPosition.indexOf(d);
      this.documentsArray.splice(p,1);
      this.documentsArrayPosition.splice(p,1);
    }


    console.log(this.documentsArray);
  }

  delete = (e:any) =>{
    console.log("eliminando");

    let p = this.documentsArrayPosition.indexOf(e);
    this.documentsArray.splice(p,1);
    this.documentsArrayPosition.splice(p,1);

    console.log(this.documentsArray);
  }

  seeDocument = (e:any) => {

    if(e.files.length==0){
      Swal.fire({
        icon: 'error',
        title: 'Sin archivo',
        text: 'No se ha cargado un archivo',
      })
    }else{
      if (e.files[0].type != 'application/pdf') {

        Swal.fire({
          icon: 'error',
          title: 'Tipo de archivo incorrecto',
          text: 'El archivo cargado no es un pdf',
        })

      } else {
        var reader = new FileReader();
      reader.readAsDataURL(e.files[0]);

      reader.onloadend = () => {
        const base64data = reader.result as string;
        const documentData = base64data;
        this.urlSafeDocument = this.sanitizer.bypassSecurityTrustResourceUrl(documentData);
      };
      this.viewDocument = true;
      }
    }
  }

  closeDocument = () => {
    this.viewDocument = false;
  }

  getIdNumber(id:any){

    console.log(id);

    this.requestService.GetDocumentTypesShared().subscribe((r1) => {
      let aux1 = r1.data.filter((e1:any) => e1.idTipoIdentificacion == id);
      this.requestService.GetDocumentTypes("bTipoIdentificacion").subscribe((r2) => {
        console.log(aux1[0]);
        console.log(r2);
        const aux2 = r2.data.filter((e2:any) => {
          return aux1[0].codigo == e2.vcNombre;
        });
        console.log(aux2[0]);
        if(aux2[0]!=undefined)
        {
          this.idDocumentType =  aux2[0].idParametroDetalle;
        }

      })
    });
  }

  getIdRequestType(){
    this.requestService.GetDocumentTypes('bTipoSolicitud').subscribe((r) => {
      let aux = r.data.filter((e:any) => e.vcNombre == "Primera vez");
      this.idRequestType = aux[0].idParametroDetalle;
    });
  }

  trainer = (e:any) => {
    this.datosTrainers = e;
    console.log(this.datosTrainers);
  }

  updateTrainer = (e:any) => {
    console.log("You should update this record");
    console.log(e);
  }

}
