// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  PROCEDURE_SHARED_URI: 'https://apm-aeu-sds-dev-shared.azure-api.net/tramites-shared/api',
  PROCEDURE_SECURITY_URI: 'https://apm-aeu-sds-dev-shared.azure-api.net/security/api/v2/Security',
  PROCEDURE_NOTIFICATIONS_URI: 'https://apm-aeu-sds-dev-shared.azure-api.net/notifications/api/v1',
  PROCEDURE_FILE_MANAGER_URI: 'https://apm-aeu-sds-dev-shared.azure-api.net/filemanager/api/v1',
  //PROCEDUREGENERAL: 'https://localhost:7297/api',
  //PROCEDUREGENERAL: 'https://localhost:44316/api',
  //PROCEDUREGENERAL: 'https://app-sds-manipalimentos-dev-eastus.azurewebsites.net/api',
  PROCEDUREGENERAL: 'https://apm-aeu-sds-dev-shared.azure-api.net/Tramites-Manipalimentos/api',
  KEY_RECAPTCHA_SITE_WEB: '6Lel3eMmAAAAALkg4o93dldoWIAP50FH4KE-E3ii',

  VUDTS_URL: 'https://dev.ventanillavirtualtramites.saludcapital.gov.co',
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
