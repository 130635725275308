import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
const { PROCEDURE_SHARED_URI } = environment;
import {CancelationSave} from 'src/app/core/models/CancelationSave';
import {CancelationSaveJ} from 'src/app/core/models/CancelationSaveJ';

@Injectable({
  providedIn: 'root'
})
export class Properties {


  constructor(private http: HttpClient) { }

  getRequest() {
    return this.http.get<any[]>(`https://localhost:5001/api/v1/Request/GetAllRequestTypes`);
  }

  getRequestCancelType() {
    return this.http.get<any[]>(`https://localhost:5001/api/v1/Request/GetAllReasonCancellationTypes`);
  }

  recordCancelForm(cancelationSave: CancelationSave): Observable<any> {
    const url = 'https://localhost:5001/api/v1/Request/SaveRequestCancellationNaturalPerson';
    return this.http.post<any>(url, cancelationSave);
  }

  recordCancelFormJ(CancelationSaveJ: CancelationSaveJ):Observable<any>{
    const urlJ = 'https://localhost:5001/api/v1/Request/SaveRequestCancellationLegalPerson';
    return this.http.post<any>(urlJ, CancelationSaveJ);
  }


}
