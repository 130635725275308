<div>
  <div class="d-flex justify-content-end mr-5">
      <button (click)="reports()" type="button" class="button btn-primary" id="reports">REPORTES</button>
  </div>

  <div class="filtros mb-5">
      <form [formGroup]="selectOptionFilterForm">
          <div class="form-row">
              <div class="form-group col-sm-12 col-md-3 col-xs-3 col-lg-3 col-xl-3">
                  <label class="d-block">Filtrar por</label>
                  <select formControlName="selectOptionFilterInput" style="height: 35px;" class="form-select" aria-label="Seleccione"(change)="changestatus()">
                      <option value="0" hidden>Seleccione una opción</option>
                      <option value="1">ID solicitud</option>
                      <option value="2">Solicitante</option>
                      <option value="3">No. identificación</option>
                      <option value="4">Tipo de solicitud</option>
                      <option value="5">Tipo de solicitante</option>
                      <option value="6">Fecha de solicitud</option>
                      <option value="7">Estado de la solicitud</option>
                  </select>
              </div>
              <div class="form-group col-sm-12 col-md-3 col-xs-3 col-lg-3 col-xl-3"*ngIf="searchbar">
                    <input style="margin-top: 30px !important;" formControlName="valueFilterInput" class="form-control mx-3 d-inline input_search"
                    type="text" placeholder="Ingrese su búsqueda">
              </div>
            <div class="form-group col-sm-12 col-md-3 col-xs-3 col-lg-3 col-xl-3"*ngIf="datebar">
              <input formControlName="fecha" class="form-control search_input_icon" type="date" style="margin-top: 30px;" />
            </div>
              <div class="form-group col-sm-12 col-md-3 col-xs-3 col-lg-3 col-xl-3 botonDivFiltrar">
                  <button class="btn btn-round btn-middle mx-3 search_coordinator botonFiltrar"
                  style="margin-top:30px !important; padding: 10px 10px;width: 12%; color: white; font-size: 17px;padding:5px 40px;border: 1px solid #3366cc;"
                  [disabled]="selectOptionFilterForm.get('selectOptionFilterInput').value==0 || selectOptionFilterForm.get('valueFilterInput').status == 'INVALID'"
                  (click)="filter()" type="button">FILTRAR</button>
              </div>
          </div>
          <div class="form-row">
              <div class="form-group col-md-12 col-sm-12 col-xs-12 col-xl-12 col-lg-12">
                <div *ngIf="selectOptionFilterForm.get('valueFilterInput').status=='INVALID'" class="alert mt-2"
                style="background-color: #e6effd;color: #004884;font-size: 15px; height: 100px;">
                  <p>* Solo se admiten caracteres alfanuméricos, a excepción del caracter / no ingrese caracteres especiales.</p>
                  <p>* La cantidad máxima de caracteres admitidos es 50.</p>
                </div>
              </div>
          </div>
      </form>
  </div>

  <nz-spin style="display: block; width: 100%;" [nzSpinning]="isLoading" [nzSize]="'large'" nzTip="Se estan cargando los datos, por favor espere un momento....">
      <app-procedure-dashboard [data]="dataFilterTableManagement" [structure]="structureTable" (linkEvent)="redirectToViewDetail($event)"></app-procedure-dashboard>
  </nz-spin>
</div>
