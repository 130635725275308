import {Data, Router} from '@angular/router';
import { ResolutionService } from './../../../../core/services/resolution.service';
import { UsersService } from 'src/app/core/services/users.service';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppBaseComponent } from 'src/app/core/utils';
import { RequestService } from 'src/app/core/services/request.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { PopUpService } from 'src/app/core/services';
import {formatDate} from '@angular/common';
import {Usuario} from "@core-app/services/usuario";
import {SafeResourceUrl} from "@angular/platform-browser";
import Swal from "sweetalert2";
import {lastValueFrom} from "rxjs";
import {DocumentsService} from "@core-app/services/documents.service";
import {ROUTES} from "@core-app/enums";

@Component({
  selector: 'app-user-request-management',
  templateUrl: './user-request-management.component.html',
  styleUrls: ['./user-request-management.component.scss']
})
export class UserRequestManagementComponent extends AppBaseComponent {

    /**
   * Formulario reactivo de la interfaz de gestión
   */

  public isVisible: boolean = false;
  public subsanado: boolean = false;

  public s: string = "https://web.seducoahuila.gob.mx/biblioweb/upload/el%20paraiso%20de%20los%20gatos.pdf";

    public requestManagementForm: FormGroup;

    solicitud: any;

    user: any;

    capacitadores: any[];

  /**
   * Lista de capacitadores
   */
  trainers:any = [];

  trainerssubsanados:any =[];
  traineractual:any ;
  contadorPJ: number = -1;
  revision="vista";
  trainersCopy:any = [];


  auditlist: any[]=[];

  isNatural:boolean = false;

  mostrarDireccion:boolean;

  isLoading:boolean = true;
  isLoadingTrainer:boolean = true;

  stringfullname:string = '';

  solicitudes:any;

  documentTypes:any = []
  numberOrder:string='';
  dateOrder:string = '';
  documentationSolicitud:any;

  idSolicitud:number;
  usuarioInfo:string;
  fileNumber: string = "";
  usuariointerno = "";

  docreprelegal:number=0;
  legalname: string = '';
  numberdocument: string = '';

  DocumentNumero:string = '';

  typeDocument:number =0;


  idCapacitadorN:string;
  idCapacitadorJ:any[];

  idCapacitadorSolicitudArray: any[];

  detailDocumentation: { id: any; descripcion: any; path: any; aprovado: any; fileNumber: any,idTipoDocumento:any}[] = [];
  correctedDocumentation: any[] = [];
  documentation: { id: any; descripcion: any; path: any; aprovado: any; fileNumber: any,idTipoDocumento:any}[] = []

  documentationSend: { id: any; descripcion: any; path: any; aprovado: any; fileNumber: any,idTipoDocumento:any}[] = []

  detailTrainingDataTypes: { id: any; name: any; selected: boolean; }[] = [];
  updateDocumentation:boolean = true;
  vcNumerosTarjetaProfesional: string[] = [];

  showModal: boolean = false;
  isLoadingDocument:boolean = true;
  urlSafeDocument: SafeResourceUrl;
  viewfinder:boolean = true;
  /**
   * Ver detalle de capacitador
   */
  documentTypes22: any = [];
  documentoSolicitud: any;
  trainingDataTypes: { id: any; name: any; selected: boolean; }[] = [];
  viewTrainerDetailModal:boolean = false;
  constructor(private popUpService: PopUpService,
              private localStorageService: LocalStorageService,
              public requestService: RequestService,

              private fb: FormBuilder,
              private activateRoute: ActivatedRoute,
              private usuario: Usuario,
              private documentService: DocumentsService,
              private router: Router
              ) {
    super();



    this.isLoading = true;
    this.isNatural = this.activateRoute.snapshot.paramMap.get('type')=="P. Natural";
    //console.log(this.orderObj);

    this.requestManagementForm = this.fb.group(({
      applicationInformationForm: this.fb.group({
        numeroRadicado: "",
        tipoTramite: "",
        estado: "",
        tipoSolicitud: "",
        fechaSolicitud: "",
        usuarioAsignado: "0"
      }),
      basicDataForm: this.fb.group({
        primerNombre: "",
        segundoNombre: "",
        primerApellido: "",
        segundoApellido: "",
        tipoDocumento: "",
        numeroDocumento: "",
        numeroContacto: "",
        correoElectronico: "",
        direccion:""
      }),
      applicationDataForm: this.fb.group({
        tituloProfesional: "",
        numeroTarjetaProfesional: ""
      }),
      selectanswer: ['Select'],
      observation:"",
      modalTrainerForm: this.fb.group({
        primerNombre: "",
        segundoNombre: "",
        primerApellido: "",
        segundoApellido: "",
        tipoDocumento: "",
        numeroDocumento: "",
        tituloProfesional: "",
        numeroTarjetaProfesional: "",
        numeroContacto: "",
        correoElectronico: "",
        revision:""
      })
    }));

     }
  ngOnInit() {
    this.InfoRequest();
    this.audit();
    console.log("trainers oninits", this.trainers);
    const userName = JSON.parse(localStorage.getItem('currentUser')).fullName;
    this.stringfullname = userName;
    console.log(JSON.parse(localStorage.getItem("userData")));
    this.usuario.getInfoperson(JSON.parse(localStorage.getItem('currentUser')).userId).subscribe((data: any) => {

      console.log("-----------------------------");
      console.log(localStorage.getItem("userData"));
      this.isNatural = JSON.parse(localStorage.getItem("userData")).numeroIdentificacionRL == null || JSON.parse(localStorage.getItem("userData")).numeroIdentificacionRL == 0;
      console.log("soy persona natural?", this.isNatural);
      if (this.isNatural) {
        this.mostrarDireccion = true;
      } else {
        this.mostrarDireccion = false;
      }
    })


    console.log(JSON.parse(  localStorage.getItem('currentUser')));
    console.log("fullname sesion", this.stringfullname);







  }

  decree = (capacitadorTipoCapacitacion:any[], value:string):boolean => {
    let flag = false;
    if (capacitadorTipoCapacitacion.find((el:any) => el.vcTipoCapacitacion == value) !=undefined) {
      flag = true;
    }

    return flag;
  }


  public InfoRequest() {
    this.requestService.GetDocumentTypesShared().subscribe((result) => {
      console.log(result);
      this.documentTypes = result.data;
    })

    const idReq = Number.parseInt(localStorage.getItem('idSolicitud').toString()) ;
    this.requestService.GetRequest(idReq).subscribe((Solicitud)=> {
      this.solicitudes = Solicitud.data;
      this.documentationSolicitud = Solicitud.data;
      console.log("esta es aqui solicitudes", this.solicitudes);
      console.log("listado info", this.solicitudes);
      this.dateOrder = Solicitud.data.vcFechaSolicitud;
      localStorage.setItem("date",this.dateOrder);
      console.log("datos importantes", Solicitud);
      this.numberOrder = Solicitud.data.vcRadicado
      localStorage.setItem("order",this.numberOrder);
      this.idSolicitud = Solicitud.data.idSolicitud;
      this.usuarioInfo = Solicitud.data.usuarioId;
      const capacitadoresSolicitud = Solicitud.data.capacitadoresSolicitud;
      capacitadoresSolicitud.forEach((capacitador: any) => {
        const capacitadorTipoCapacitacion = capacitador.capacitadorTipoCapacitacion;
        capacitadorTipoCapacitacion.forEach((tipoCapacitacion: any) => {
          const vcTipoCapacitacion = tipoCapacitacion.vcTipoCapacitacion;
          const idCapacitadorSolicitud = tipoCapacitacion.idCapacitadorSolicitud;
          localStorage.setItem('vcTipoCapacitacion', vcTipoCapacitacion);
          localStorage.setItem('idCapacitadorSolicitud', idCapacitadorSolicitud)
        });
      });




      if (Solicitud.data.capacitadoresSolicitud && Solicitud.data.capacitadoresSolicitud.length > 0) {
        Solicitud.data.capacitadoresSolicitud.forEach((capacitador: { vcNumeroTarjetaProfesional: string }) => {
          this.vcNumerosTarjetaProfesional.push(capacitador.vcNumeroTarjetaProfesional);
        });
      }
      this.fileNumber = Solicitud.data.vcRadicado;
      this.usuariointerno=Solicitud.data.capacitadoresSolicitud[0].idCapacitadorSolicitud;
      this.requestManagementForm.get('applicationInformationForm').get('numeroRadicado').setValue(Solicitud.data.vcRadicado);
      this.requestManagementForm.get('applicationInformationForm').get('tipoTramite').setValue(Solicitud.data.vcTipoTramite);
      this.requestManagementForm.get('applicationInformationForm').get('estado').setValue(Solicitud.data.vcEstado);
      this.requestManagementForm.get('applicationInformationForm').get('tipoSolicitud').setValue(Solicitud.data.vcRadicado);
      this.requestManagementForm.get('applicationInformationForm').get('fechaSolicitud').setValue(Solicitud.data.vcFechaSolicitud);
      const idReq = localStorage.getItem('idSolicitud').toString();
      console.log(idReq);
      this.usuario.getInfoperson(Solicitud.data.usuarioId).subscribe((data: any) => {
        console.log(data);
        console.log(data['data']);
        this.usuario.getTotalperson(data['data']).subscribe((valores: any) => {
          console.log(valores.data);

          console.log("encargado", valores);
          this.legalname = valores.data.primerNombre + ' ' + valores.data.primerApellido;
          localStorage.setItem("nombre", this.legalname);
          this.numberdocument = valores.data.numeroIdentificacion;
          localStorage.setItem("document", this.numberdocument);
          let newValueTxt;
          this.typeDocument = valores.data.tipoIdentificacion;
          if (this.typeDocument === 5) {
            valores.data.razonSocial = "Cédula de ciudadanía";
            newValueTxt = valores.data.razonSocial;
            localStorage.setItem("txt", newValueTxt);
          } else {
            valores.data.razonSocial = "Sin Nombre";
          }

          this.DocumentNumero = valores.data.numeroIdentificacion;
          localStorage.setItem("textDocument",this.DocumentNumero)

          this.requestManagementForm.get('basicDataForm').get('primerNombre').setValue(valores.data.primerNombre);
          this.requestManagementForm.get('basicDataForm').get('segundoNombre').setValue(valores.data.segundoNombre);
          this.requestManagementForm.get('basicDataForm').get('primerApellido').setValue(valores.data.primerApellido);
          this.requestManagementForm.get('basicDataForm').get('segundoApellido').setValue(valores.data.segundoApellido);
          this.requestManagementForm.get('basicDataForm').get('numeroContacto').setValue(valores.data.telefonoCelular);
          this.requestManagementForm.get('basicDataForm').get('correoElectronico').setValue(valores.data.email);
          this.requestManagementForm.get('basicDataForm').get('direccion').setValue(valores.data.direResi);

          if (this.isNatural) {
            console.log("noooo entro acaaa");
            console.log(valores.data)
            this.requestManagementForm.get('basicDataForm').get('tipoDocumento').setValue(valores.data.tipoIdentificacion);
            this.requestManagementForm.get('basicDataForm').get('numeroDocumento').setValue(valores.data.numeroIdentificacion);
            //Datos de la solicitud

            this.idCapacitadorN = Solicitud.data.capacitadoresSolicitud[0].idCapacitadorSolicitud;

            let obj = null;

            this.requestService.GetTraining().subscribe((result2) => {
              console.log(result2);
              result2.data.forEach((r: { idTipoCapacitacion: any; vcDescripcion: any; }) => {

                obj = {
                  id: r.idTipoCapacitacion,
                  name: r.vcDescripcion,
                  selected: false
                }

                this.trainingDataTypes.push(obj);
              });

              this.trainingDataTypes.forEach((t) => {
                console.log("forEach trainings");
                let aux = Solicitud.data.capacitadoresSolicitud[0].capacitadorTipoCapacitacion.find((c:any) => c.vcTipoCapacitacion == t.name);

                if (aux != undefined) {
                  t.selected = true;
                }
              })
            })
            this.requestManagementForm.get('applicationDataForm').get('tituloProfesional').setValue(Solicitud.data.capacitadoresSolicitud[0].vcTituloProfesional);
            this.requestManagementForm.get('applicationDataForm').get('numeroTarjetaProfesional').setValue(Solicitud.data.capacitadoresSolicitud[0].vcNumeroTarjetaProfesional);

            //Documentation

            let doc = null;

            console.log("documentacion")
            let tipo = localStorage.getItem('tipo');
            this.viewfinder = !(tipo=='subsanacion');
            Solicitud.data.capacitadoresSolicitud[0].documentosSolicitud.forEach((ds: any) => {

              console.log(ds);
              doc = {
                id: ds.idDocumento,
                descripcion: ds.vcTipoDocumento,
                path: ds.vcPath,
                aprovado: ds.blIsValid,
                fileNumber: this.fileNumber,
                idTipoDocumento:ds.idTipoDocumento
              }
              if(tipo=='subsanacion')
              {
                if(!ds.blIsValid)
                {
                  this.documentation.push(doc);
                  this.documentationSend.push(doc);
                }

              }else {
                this.documentation.push(doc);
                this.documentationSend.push(doc);
              }
              console.log(doc)
              console.log("this are the documents");
              console.log(this.documentation);

            });

          }else{
            console.log("entro acaaa");
            console.log(valores.data)
            this.requestManagementForm.get('basicDataForm').get('tipoDocumento').setValue(valores.data.tipoIdentificacionRL);
            this.requestManagementForm.get('basicDataForm').get('numeroDocumento').setValue(valores.data.numeroIdentificacionRL);
            this.idCapacitadorJ = Solicitud.data.capacitadoresSolicitud;
            //Trainers

            let t = null;
            let index = 1;

            console.log(Solicitud.data.capacitadoresSolicitud)
            Solicitud.data.capacitadoresSolicitud.forEach((cs: any) => {

              console.log(cs ,"capacitadore");
              if(!cs.blCapacitadorActivo)
              {
                t = {
                  id: index,
                  firstName: cs.vcPrimerNombre,
                  secondName: cs.vcSegundoNombre,
                  surname: cs.vcPrimerApellido,
                  secondSurname: cs.vcSegundoApellido,
                  documentType: cs.vcTipoIdentificacion,
                  identificationNumber: cs.intNumeroIdentificacion,
                  professionalTitle: cs.vcTituloProfesional,
                  professionalCardNumber: cs.vcNumeroTarjetaProfesional,
                  telephoneContact: cs.intTelefono,
                  emailAddress: cs.vcEmail,
                  approved: cs.blCapacitadorActivo
                }
                this.trainers.push(t);
                this.trainersCopy.push(t);
                index++
              }


            });

            this.contadorPJ = this.trainers.length;
            console.log(this.contadorPJ);
          }

        });
      });
      this.getData();
    });
  }

  getData() {
    const idReq = Number.parseInt(localStorage.getItem('idSolicitud').toString()) ;
    this.requestService.GetRequest(idReq).subscribe((Solicitud) => {
      console.log("Holaaaa")
      console.log(this.solicitudes)

      const idCapacitadorSolicitudArray = this.solicitudes.capacitadoresSolicitud.map((capacitador: any) => { return capacitador.idCapacitadorSolicitud; });


      const documentAndStateArray = Solicitud.data.capacitadoresSolicitud.flatMap((capacitador: any) => {
        return capacitador.documentosSolicitud.map((documento: any) => {
          return {
            idDocumento: documento.idDocumento,
            blIsValid: documento.blIsValid
          };
        });
      });

      const documentoSolicitud = documentAndStateArray.map((documentState: any) => {
        return {
          idDocumento: documentState.idDocumento,
          blIsValid: documentState.blIsValid
        };
      });

      this.idCapacitadorSolicitudArray = idCapacitadorSolicitudArray;
      this.documentoSolicitud = documentoSolicitud;
    });
    this.isLoading = false;
  }

  audit() {

    const idReq = Number.parseInt(localStorage.getItem('idSolicitud').toString()) ;
    this.requestService.GetRequest(idReq).subscribe((validate) => {
      this.auditlist = validate.data.seguimientoAuditoriaSolicitud;
    });
  }

  viewDetailTrainer = async (e:any) => {

    this.traineractual=e.id;

    this.correctedDocumentation.splice(0,this.correctedDocumentation.length)
    this.isLoadingTrainer = true;
    console.log('Trainer Detail');
    this.detailDocumentation = [];
    this.detailTrainingDataTypes = [];
    console.log(e);
    this.viewTrainerDetailModal = true;

    this.requestService.GetDocumentTypes('bTipoIdentificacion').subscribe((result) =>{
      this.documentTypes22 = result.data;
    })


    this.requestManagementForm.get("modalTrainerForm").get("primerNombre").setValue(e.firstName);
    this.requestManagementForm.get("modalTrainerForm").get("segundoNombre").setValue(e.secondName);
    this.requestManagementForm.get("modalTrainerForm").get("primerApellido").setValue(e.surname);
    this.requestManagementForm.get("modalTrainerForm").get("segundoApellido").setValue(e.secondSurname);
    this.requestManagementForm.get("modalTrainerForm").get("tipoDocumento").setValue(e.documentType);
    this.requestManagementForm.get("modalTrainerForm").get("numeroDocumento").setValue(e.identificationNumber);
    this.requestManagementForm.get("modalTrainerForm").get("tituloProfesional").setValue(e.professionalTitle);
    this.requestManagementForm.get("modalTrainerForm").get("numeroTarjetaProfesional").setValue(e.professionalCardNumber);
    this.requestManagementForm.get("modalTrainerForm").get("numeroContacto").setValue(e.telephoneContact);
    this.requestManagementForm.get("modalTrainerForm").get("correoElectronico").setValue(e.emailAddress);
    this.requestManagementForm.get("modalTrainerForm").get("revision").setValue("revision");

    await this.requestService.GetRequest(parseInt(localStorage.getItem('idSolicitud').toString())).subscribe((result) => {
      console.log("datos de la solicitud para capacitador")
      console.log(result);

      //Datos de la solicitud

      let obj = null;

      let capacitador:any = null;

      this.requestService.GetTraining().subscribe((result2) => {
        console.log(result2);
        this.documentationSolicitud=result.data;
        result2.data.forEach((r: { idTipoCapacitacion: any; vcDescripcion: any; }) => {

          obj = {
            id: r.idTipoCapacitacion,
            name: r.vcDescripcion,
            selected: false
          }

          this.detailTrainingDataTypes.push(obj);
        });
        console.log(this.documentationSolicitud);
        console.log(e.identificationNumber);
        this.documentationSolicitud.capacitadoresSolicitud.forEach((cs:any) => {
          if(cs.intNumeroIdentificacion == e.identificationNumber){
            console.log("I find it");
            capacitador = cs;
          }
        });

        console.log(capacitador);

        this.detailTrainingDataTypes.forEach((t) => {
          console.log("forEach trainings");

          let aux = capacitador.capacitadorTipoCapacitacion.find((c:any) => c.vcTipoCapacitacion == t.name);

          if (aux != undefined) {
            t.selected = true;
          }
        })

        //Documentation

        let doc = null;


        for (let i = 0; i < this.trainerssubsanados; i++) {
          console.log(this.trainerssubsanados);
          console.log(this.traineractual);
          if(this.trainerssubsanados[i]==this.traineractual)
          {
            this.subsanado=true;
            break;
          }
        }

        let tipo = localStorage.getItem('tipo');
        capacitador.documentosSolicitud.forEach((ds: any) => {

          console.log(ds);
          doc = {
            id: ds.idDocumento,
            descripcion: ds.vcTipoDocumento,
            path: ds.vcPath,
            aprovado: ds.blIsValid,
            fileNumber: this.fileNumber,
            idTipoDocumento:ds.idTipoDocumento
          }
          if(tipo=='subsanacion')
          {
            if(!this.subsanado)
            {
              if(!ds.blIsValid)
              {
                this.detailDocumentation.push(doc);
              }
            }


          }else {
            this.detailDocumentation.push(doc);
          }

        });

        console.log(this.detailDocumentation);

        this.isLoadingTrainer = false;
      })
    });

  }
  addModalSelectedTraining = (e:any) => {
    console.log("lista de entrenamientos a asignar del modal");
    console.log(e);
  }
  closeDocument = () => {
    this.viewTrainerDetailModal = false;
  }
  addSelectedTraining = (e:any) => {
    console.log("lista de entrenamientos a asignar");
    console.log(e);
  }
  addApprovedDocumentation = (e:any) => {
    console.log("lista de documentacion a asignar");
    console.log(e);
    this.documentationSend.forEach((element) => {

      if (element.id == e.record.id) {
        element.aprovado = e.value == 'si';
      }

    })
    //console.log(this.documentationSend);
    this.updateDocumentation = false;
  }
  approvedTrainer = (e:any) => {
    console.log('approved Trainer');
    console.log(e);

    this.trainersCopy.forEach((element:any) => {

      if(element.identificationNumber == e.record.identificationNumber){
        element.approved = e.value == "si";
      }
    });
  }
  Volver = () => {

    this.router.navigateByUrl(`${ROUTES.MainPage}/${ROUTES.Request}/${ROUTES.SearchRequest}`);


  }
  GetDocument = (e:any) => {

    console.log(e);

    if(e.delete=="si")
    {
      for (let i = 0; i <this.correctedDocumentation.length ; i++) {
        if(this.correctedDocumentation[i].document.id ==e.document.id)
        {
          this.correctedDocumentation.splice(i,1);
          break;
        }
      }
    }
    else {
      if(e.file.file.target.files.length>0)
      {
        let posicion=-1;


        for (let i = 0; i <this.correctedDocumentation.length ; i++) {
          if(this.correctedDocumentation[i].document.id ==e.document.id)
          {
            posicion=i;

            this.correctedDocumentation[i]={file: e.file.file.target.files[0], document: e.document}
            break;
          }

        }
        if(posicion==-1 || this.correctedDocumentation.length==0)
        {

          this.correctedDocumentation.push({file: e.file.file.target.files[0], document: e.document});
        }


        //console.log(this.documentsArray);
      }
      else {
        for (let i = 0; i <this.correctedDocumentation.length ; i++) {
          if(this.correctedDocumentation[i].document.id ==e.document.id)
          {
            this.correctedDocumentation.splice(i,1);
            break;
          }
        }
        /*
        let p = this.documentsArrayPosition.indexOf(d);
        this.documentsArray.splice(p,1);
        this.documentsArrayPosition.splice(p,1);

         */
      }
    }



  }
  SaveSubsanation = async (e: any) => {

    console.log(e);
    this.isLoading=true;
    this.isLoadingTrainer=true;
    this.isLoadingDocument=true;

    for (let i = 0; i < this.trainerssubsanados; i++) {
      if(this.trainerssubsanados[i]==this.traineractual)
      {
        this.subsanado=true;
        break;
      }
    }
    if(!this.subsanado){
        let correcto = true;
        if (this.isNatural) {
          console.log(this.correctedDocumentation.length);
          console.log(this.correctedDocumentation);
          console.log(this.documentation);
          console.log(this.documentation.length);
          if (this.correctedDocumentation.length != this.documentation.length) {
            correcto = false;
            Swal.fire({
              icon: 'error',
              title: '<h5>Debe Subsanar todos los Documentos</h5>',
              text: 'Faltan documentos obligatorios por cargar.',
              allowOutsideClick: false
            });
          }
        } else {

          console.log(this.correctedDocumentation.length);
          console.log(this.correctedDocumentation);
          console.log(this.documentation);
          console.log(this.documentation.length);
          console.log(this.detailDocumentation);
          console.log(this.detailDocumentation.length);

          if (this.correctedDocumentation.length != this.detailDocumentation.length) {
            correcto = false;
            Swal.fire({
              icon: 'error',
              title: '<h5>Debe Subsanar todos los Documentos</h5>',
              text: 'Faltan documentos obligatorios por cargar.',
              allowOutsideClick: false
            });
          }else{
            console.log(this.contadorPJ);
            this.contadorPJ--;
            console.log(this.contadorPJ);
          }
        }

        
        if (correcto) {

          let json = [];
          const idReq = Number.parseInt(localStorage.getItem('idSolicitud').toString());
          const usuarioid = JSON.parse(localStorage.getItem('currentUser')).userId;
          for (const element of this.correctedDocumentation) {

            json.push(
              {
                idDocumento: element.document.id,
                solicitudId: idReq,
                usuarioId: usuarioid,
                tipoDocumentoId: element.document.idTipoDocumento,
                vcNombreDocumento: element.document.descripcion,
                vcPath: element.document.path,
              }
            )


            let path = element.document.path;
            const formData = new FormData();
            const reader = new FileReader();
            reader.onload = (e: any) => {
              console.log(path)
              console.log(element)
              console.log(e.target.result);
              formData.append("Oid", this.fileNumber);
              formData.append("NameFile", path);
              formData.append("ContainerName", "manipalimentos");
              formData.append("File", new Blob([new Uint8Array(e.target.result)], {type: "application/pdf"}));
              this.documentService.addBlob(formData).subscribe((respuesta: any) => {
                console.log(respuesta);
                if(respuesta.code== 201){
                  let s:string = path;
                  this.trainers = this.trainers.filter((x: { identificationNumber: any; })=> x.identificationNumber != s.substring(0,s.indexOf("_")));
                }
              })
            };
            reader.readAsArrayBuffer(element.file);
          }

          this.usuario.SaveSubsanation(idReq, json).subscribe((validate) => {
            console.log(validate);


            if (this.isNatural) {

              Swal.fire({
                text: 'Subsanación exitosa',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar',
              }).then((result) => {
                if (result.isConfirmed) {
                  this.router.navigateByUrl(`${ROUTES.MainPage}/${ROUTES.Request}/${ROUTES.Menu}`);
                  //window.history.back();
                }
              });
              
            }else{
              /*
              Swal.fire({
                text: 'Subsanación exitosa',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar',
              });
              */

              console.log(this.trainers);
              if (this.contadorPJ == 0) {

                Swal.fire({
                  text: 'Subsanación enviada',
                  icon: 'success',
                  showCancelButton: false,
                  confirmButtonColor: '#3085d6',
                  confirmButtonText: 'Aceptar',
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.router.navigateByUrl(`${ROUTES.MainPage}/${ROUTES.Request}/${ROUTES.Menu}`);
                    //window.history.back();
                  }
                });
                
              }
            }

          });

          console.log("Los trainers son: ");
          console.log(this.trainers);

          this.isLoading=false;
          this.isLoadingTrainer=false;
          this.isLoadingDocument=false;
          this.trainerssubsanados.push(this.traineractual);
          this.traineractual=null;
          this.subsanado=false;
          this.documentation=[];
          this.detailDocumentation=[];
          this.correctedDocumentation=[];
          this.closeDocument();

        }
        
      }else{
        this.subsanado=false;
        this.isLoading=false;
        this.isLoadingTrainer=false;
        this.isLoadingDocument=false;
        this.closeDocument();
      }
      
  }


}

