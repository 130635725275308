<div class="row lista">
  <div class="col-lg-12">
      <div>
          <table #tablaHijo class="table tabla-hijo" style=" border: 2px solid #f8f9fc;" >
              <thead class="thead-light">
                  <tr>
                      <th class="text-center body-1-gov-co"><strong>Nox.</strong></th>
                      <th class="text-center body-1-gov-co"><strong>Nombre</strong></th>
                      <th class="text-center body-1-gov-co"><strong>Acciones</strong></th>
                      <th class="text-center body-1-gov-co"*ngIf="titulo!='vista'"  ><strong>Cumple</strong></th>
                  </tr>
              </thead>
              <tr class="space" *ngFor="let t of trainers;let i = index">
                  <td class="text-center">{{t.id}}</td>
                  <td class="text-center">{{t.firstName+" "+t.surname}}</td>
                  <td class="text-center"><a (click)="viewDetail(t)" style="color: #004884; text-decoration: underline;">Ver detalle</a></td>
                  <td *ngIf="titulo!='vista'"  >
                      <div class="d-flex justify-content-around" [attr.disabled]="cancelacion ? true : null">
                          <div class="ml-5 d-inline-block">
                              <input [disabled]="!t.disabledFlag || flag" (click)="approvedTrainer(t, 'si')" [checked]="t.approved" value="si" type="radio" name="approvedTrainer{{i}}" />
                              <label>Si</label>
                          </div>
                          <div class="mr-5 d-inline-block">
                              <input [disabled]="flag" (click)="approvedTrainer(t, 'no')" [checked]="!t.approved" value="no" type="radio" name="approvedTrainer{{i}}" />
                              <label>No</label>
                          </div>
                      </div>
                  </td>
              </tr>
          </table>
      </div>
  </div>
</div>
