<div class="row mt-3">
  <div class="col-lg-10 col-sm-12">
      <app-advance-line [width]="123" [step]="1" [currentProgress]="100" [options]="advanceLineData"></app-advance-line>
  </div>
</div>
<div class="row mt-4">
  <div class="col-lg-12 mt-3">
      <div class="heading">
          <p class="title_section">Autorización para capacitadores de manipulación de alimentos</p>
      </div>
  </div>
  <div class="col-lg-8 col-md-8 col-sm-12 col-xxl-8 mt-3">
      <div class="text_info">
          <p class="text-justify info_second">
              Autorización para desarrollar programas de educación sanitaria, que permite capacitar al personal que manípula, procesa y expende alimentos y productos cárnicos comestibles, alimentos comercializados en vía pública, leche
              cruda para consumo humano directo comercializada de manera ambulante, y objetos, envases, materiales y equipamientos en contacto con alimentos
          </p>
      </div>
  </div>
  <div class="col-lg-3 col-md-3 col-sm-12 col-xs-2 col-xl-3 float-right tutoriales">
      <div class="custom-alert">
          <a href="https://aulavirtual.saludcapital.gov.co/sicap/" style="text-decoration: none !important;" target="_blank">
              <div class="alert alert-primary" style="text-align: left; font-size: 16px !important;" role="alert">
                  Te explicamos con tutoriales
              </div>
          </a>
          <a type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
              <div class="alert alert-primary" style="text-align: left; font-size: 16px !important;" role="alert">
                  ¿Tienes dudas?
              </div>
          </a>
          <div class="collapse" id="collapseExample">
              <div class="card card-body" id="cardi_info_contact">
                <p id="colorphone" style="margin-left: 18px;">Teléfono:(571) 3649090</p>
                <p id="colorphone" style="margin-left: 18px; margin-top: -10px;">contactenos&commat;aludcapital.gov.co</p>
              </div>
          </div>
      </div>
  </div>
</div>

<div class="row justify-content-center mt-3 py-5">
  <div class="col-md-6 col-lg-6 col-sm-12 col-xxl-6" id="left">
      <div class="card gov-co-tramite-card mx-auto card-equal-height" id="card_first">
          <div class="card-header text-center" id="created_left">Crear solicitud</div>
          <div class="card-body">
              <ul>
                  <li *ngIf="!hasSolicitudes"><a (click)="firstime()">Primera vez</a></li>

              </ul>
          </div>
      </div>
  </div>
  <div class="col-md-6 col-lg-6 col-sm-12 col-xxl-6" id="right">
      <div class="card gov-co-tramite-card mx-auto card-equal-height" id="card_second">
          <div class="card-header text-center">Consultar solicitud</div>
          <div class="card-body">
              <ul>
                  <li><a (click)="inbox()">Consulte el estado de su solicitud</a></li>
              </ul>
          </div>
      </div>
  </div>
</div>
