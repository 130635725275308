<nz-spin style="display: block;width: 100%;" [nzSpinning]="isLoading" [nzSize]="'large'"
         nzTip="Se estan cargando los datos, por favor espere un momento....">

  <h4 class="headline-l-govco mb-4">Autorización para capacitadores de manipulación de alimentos</h4>

  <form [formGroup]="requestManagementForm">
    <div class="container">
      <div class="accordion-govco" id="accordion">
        <div class="card border border-0">
          <span class="headline-m-govco">Información de la solicitud</span>
          <app-application-information></app-application-information>
        </div>
        <div class="card border border-0">
          <span
            class="headline-m-govco">{{ isNatural ? 'Datos básicos' : 'Datos del Representante Legal' }}</span>
          <app-basic-data2 [documentTypes]="documentTypes" [mostrarDireccion]="mostrarDireccion"></app-basic-data2>

        </div>
        <div *ngIf="isNatural" class="card border border-0">
          <div class="card-header" id="datos-solicitud-header">
            <span class="headline-m-govco">Datos de la
                  solicitud</span>
            <app-application-data [trainingDataTypes]="trainingDataTypes"
                                  (trainingDataTypesSelectedEvent)="addSelectedTraining($event)"></app-application-data>
         </div>

        </div>
        <div *ngIf="isNatural" class="card border border-0">
          <div class="card-header" id="archivos-adjuntos-header">
            <span class="headline-m-govco">Archivos
                  adjuntos</span>

            <app-attached-files [viewfinder]="viewfinder" [documentation]="documentation"
                                (approvedDocumentationEvent)="GetDocument($event)"></app-attached-files>

          </div>
        </div>
        <div *ngIf="!isNatural" class="card border border-0">
          <div class="card-header" id="informacion-capacitadores-header">
            <span class="headline-m-govco">Listado de capacitadores</span>
            <app-list-of-trainers [titulo]="revision" [trainers]="trainers" (approvedTrainerEvent)="approvedTrainer($event)"
                                  (viewDetailEvent)="viewDetailTrainer($event)"></app-list-of-trainers>

          </div>

        </div>
        <div style="padding: 0px 200px; margin-top: 50px;">
          <div [ngStyle]="{'display':isNatural?'grid':'block'}" style="grid-template-columns: 1fr 1fr;">
            <div *ngIf="isNatural" class="d-flex justify-content-center w-100 documentv">
              <button (click)="SaveSubsanation($event)" class="btn btn-round btn-high mx-3"
                      style="color: white; font-size: 17px;padding:5px 40px;">
                Guardar
              </button>
            </div>
            <div class="d-flex justify-content-center w-100 documentv">
              <button (click)="Volver()" class="btn btn-round btn-high mx-3"
                      style="color: white; font-size: 17px;padding:5px 40px;">
                Volver
              </button>
            </div>
          </div>
        </div>



        <div class="documentViewer">
          <app-document-viewer [isLoadingDocument]="isLoadingDocument" [isVisible]="showModal" [document]="urlSafeDocument"
                               (closeButtonEvent)="closeDocument()"></app-document-viewer>
        </div>
        <div class="modals">
          <app-modals [isLoading]="isLoadingTrainer"
                      [documentation]="detailDocumentation"
                      (approvedDocumentationEvent)="GetDocument($event)"
                      (trainingDataTypesSelectedEvent)="addModalSelectedTraining($event)"
                      [trainingDataTypes]="detailTrainingDataTypes" [documentTypes]="documentTypes22"
                      [isVisible]="viewTrainerDetailModal" [type]="'trainerInformation'"
                      (closeButtonEvent)="closeDocument()"
                      (acceptButtonTrainerEvent)="SaveSubsanation($event)"
                      >
          </app-modals>
        </div>

    </div>
      </div>

  </form>
</nz-spin>
