<div class="line">
  <div [ngStyle]="{width: width+'%'}" class="line">
    <div class="gov-co-advance">
      <div class="progress">
        <div [ngStyle]="{width: currentProgress+'%'}" class="progress-bar" role="progressbar" aria-valuenow="25"
             aria-valuemin="0" aria-valuemax="40"></div>
      </div>
      <div class="navs-link-advance text">
        <div [ngStyle]="{width: (100/options.length)+'%'}" style="display: inline" *ngFor="let op of options; let i = index">
          <a (click)="redirectTo(op.link, i+1)" [ngClass]="{'active': step >= i+1, 'advance': step < i+1}" class="nav-link-advance justify-content-start p-2">
            <span class="circle-number">{{i+1}}</span> {{op.name}}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
