import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ROUTES } from '@core-app/enums';
import { RequestService } from '@core-app/services/request.service';
import { Usuario } from '@core-app/services/usuario';

@Component({
  selector: 'app-assistant-manager-tab',
  templateUrl: './assistant-manager-tab.component.html',
  styleUrls: ['./assistant-manager-tab.component.scss']
})
export class AssistantManagerTabComponent {
  public selectOptionFilterForm: FormGroup;
  isLoading:boolean = true;
  public dataFilterTable: any;
  public dataTableManagement: any = [];
  public dataFilterTableManagement: any = [];
  public dataFilterTableTrainingsFollowUp: any;

  public searchbar:boolean=true;
  public datebar:boolean=false;
   /**
    * Columnas de la bandeja del logistico
    */

   public structureTable: any= [
    {
      name: 'ID solicitud',
      type: 'texto',
      value: 'vcRadicado'
    },
    {
      name: 'Solicitante',
      type: 'texto',
      value: 'vcNombreUsuario'
    },
    {
      name: 'No. identificación',
      type: 'texto',
      value: 'intNumeroIdentificacionUsuario'
    },
   {
      name: 'Tipo de solicitud',
      type: 'texto',
      value: 'vcNombre'
    },
    {
      name: 'Tipo de solicitante',
      type: 'texto',
      value: 'vcTipoSolicitante'
    },
    {
      name: 'Fecha de solicitud',
      type: 'texto',
      value: 'dtFechaSolicitud'
    },
    {
      name: 'Estado',
      type: 'texto',
      value: 'vcTipoEstado'
    },
    {
      name: 'Acciones permitidas',
      type: 'enlace',
      value: 'Ver detalle'
    },
    {
      name: 'Tiempo de atención restante',
      type: 'remainingDays',
      value1: 'diasRestantes',
      value2: 'diasHabiles',
      color1: '#e6effd',
      color2: '#ff574d',
    }
   ];


   constructor(private router: Router, private fb:FormBuilder, private usuario : Usuario, private requestService: RequestService){
    this.isLoading = true;
    this.selectOptionFilterForm = this.fb.group({
      selectOptionFilterInput: ['0'],
      fecha:[],
      valueFilterInput: ['', [Validators.pattern('[a-zA-Zóé0-9 /.-]*'), Validators.maxLength(50)]]
    });


    const idUser = JSON.parse(localStorage.getItem('currentUser')).userId;
    let color = true;

    this.requestService.GetAllRequestsAssistantManager(idUser).subscribe((result) => {
      console.log("----datos----");
      console.log(result);
      console.log("id present", idUser);
      if(result.data !=null){

        result.data.forEach((e:any) => {

          let isNatural:boolean = e.vcTipoSolicitante == 'P. Natural';
          console.log(isNatural?'natural':'juridica');

          const [day, month, year] = e.dtFechaSolicitud.split('/');
          let date = new Date(year+"-"+month+"-"+day);
          date.setDate(date.getDate() + 1);
          let fecha = date.getFullYear() + "-"+ (date.getMonth()+1).toString().padStart(2,'0') + "-" + date.getDate().toString().padStart(2,'0');
          this.requestService.getRemainingDays('2CB35A45-12D8-4AAB-8A01-5DC5FDDAC591', fecha).subscribe((rd) => {
            e.diasRestantes = isNatural?rd.data.personaNatural.diasRestantes:rd.data.personaJuridica.diasRestantes;
            e.diasHabiles = isNatural?rd.data.personaNatural.totalANS:rd.data.personaJuridica.totalANS;
          })
        });
        this.dataTableManagement = result.data;
        this.dataFilterTableManagement = this.dataTableManagement;
      };
      this.isLoading = false;
    })
  }

  ngOnInit(){

  }

  reports(){
    this.router.navigate([`aut-cap/request/generalReports`]);
  }
  changestatus(){
    if(this.selectOptionFilterForm.get('selectOptionFilterInput').value=="6")
    {
      this.datebar=true;
      this.searchbar =false;
    }
    else {
      this.datebar=false;
      this.searchbar =true;
    }
  }

   redirectToViewDetail(e:any){
    console.log("Record to redirect to view detail");
    console.log(e);
    console.log(e.record.vcTipoEstado);
    let cadena:string = e.record.vcTipoEstado;
    let inicio:number = cadena.indexOf("-")+2;
    let fin:number = cadena.length;
    console.log(cadena.substring(inicio, fin));
    localStorage.setItem("resValS", cadena.substring(inicio, fin))
    localStorage.setItem('Rol',"subdirector");
    this.router.navigateByUrl(`${ROUTES.MainPage}/${ROUTES.Dashboard}/${ROUTES.REQUEST_MANAGEMENT_ASSISTANT_MANAGER}/${e.record.idSolicitud}/${e.record.vcTipoSolicitante}`);
  }

  filter(){
    console.log('Cantidad de datos: '+this.dataFilterTableManagement.length);
    console.log(this.dataFilterTableManagement);



    switch (this.selectOptionFilterForm.get('selectOptionFilterInput').value) {
      case '1':
        this.dataFilterTableManagement = this.dataTableManagement.filter((d: { vcRadicado: any; }) => d.vcRadicado.includes(this.selectOptionFilterForm.get('valueFilterInput').value));
        break;
      case '2':
        this.dataFilterTableManagement = this.dataTableManagement.filter((d: { vcNombreUsuario: string; }) => d.vcNombreUsuario.includes(this.selectOptionFilterForm.get('valueFilterInput').value));
      break;
      case '3':
        this.dataFilterTableManagement = this.dataTableManagement.filter((d: { intNumeroIdentificacionUsuario: any; }) => d.intNumeroIdentificacionUsuario.includes(this.selectOptionFilterForm.get('valueFilterInput').value));
      break;
      case '4':
        this.dataFilterTableManagement = this.dataTableManagement.filter((d: { vcNombre: any; }) => d.vcNombre.includes( this.selectOptionFilterForm.get('valueFilterInput').value));
      break;
      case '5':
      this.dataFilterTableManagement = this.dataTableManagement.filter((d: { vcTipoSolicitante: any; }) => d.vcTipoSolicitante.includes(this.selectOptionFilterForm.get('valueFilterInput').value));
      break;
      case '6':
        let fecha="";
        if(this.selectOptionFilterForm.get('fecha').value!=null)
        {
          fecha=this.selectOptionFilterForm.get('fecha').value.toString().split('-')
          fecha=fecha[2]+"/"+fecha[1]+"/"+fecha[0]
        }

        this.dataFilterTableManagement = this.dataTableManagement.filter((d: { dtFechaSolicitud: any; }) => d.dtFechaSolicitud == fecha);
      break;
      case '7':
        this.dataFilterTableManagement = this.dataTableManagement.filter((d: { vcTipoEstado: any; }) => d.vcTipoEstado.includes(this.selectOptionFilterForm.get('valueFilterInput').value));
      break;
      default:
        break;
    }
    console.log('Resultados filtrados');
    console.log('Cantidad de datos filtrados: '+this.dataFilterTableManagement.length);
    console.log(this.dataFilterTableManagement);
  }



}
