import { filter } from 'rxjs';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Usuario } from '@core-app/services/usuario';
import Swal from 'sweetalert2';
import clone from 'just-clone';
import {AppBaseComponent} from "../../../../shared/components/appBase.component";
declare var $: any;

@Component({
  selector: 'app-trainers',
  templateUrl: './trainers.component.html',
  styleUrls: ['./trainers.component.scss']
})

export class TrainersComponent extends AppBaseComponent implements OnInit {

  documents: any = [
    {
      id: 1,
      name: 'Cédula de ciudadanía',
      mandatory: true,
      initials: 'cc'
    },
    {
      id: 2,
      name: 'Carta de solicitud',
      mandatory: true,
      initials: 'cs'
    },
    {
      id: 3,
      name: 'Hoja de vida',
      mandatory: true,
      initials: 'hv'
    },
    {
      id: 4,
      name: 'Descripción detallada del curso',
      mandatory: true,
      initials: 'dtc'
    },
    {
      id: 5,
      name: 'Pensum de pregrado',
      mandatory: false,
      initials: 'pp'
    },
    {
      id: 6,
      name: 'Diploma profesional / tecnólogo',
      mandatory: true,
      initials: 'dpt'
    },
    {
      id: 7,
      name: 'Tarjeta profesional',
      mandatory: true,
      initials: 'tp'
    },
    {
      id: 8,
      name: 'Resumen didáctico del curso',
      mandatory: true,
      initials: 'rdc'
    },
    {
      id: 9,
      name: 'Memorias ilustradas',
      mandatory: true,
      initials: 'mi'
    }
  ]

  nuevoDatoConTexto:any = null;

  documentsArray: any = [];

  isUpdating = false;

  updateFlag = false;

  nameFile:any = {cc: "", cs: "", hv:"", dtc:"", pp:"",dpt:"", tp:"",rdc:"", mi:""};

  sizeFile:any = {cc: 0, cs: 0, hv:0, dtc:0, pp:0,dpt:0, tp:0,rdc:0, mi:0};

  documentsArrayPosition: any = [];

  urlSafeDocument: SafeResourceUrl;

  viewDocument:boolean = false;

  @Output() datosTrainers = new EventEmitter<any>();

  @Output() updateTrainer = new EventEmitter<any>();

  fileForm: FormGroup;

  /**
   * Formulario de los capacitadores
   */
  trainerForm: FormGroup;
  selectdni: any[] = [];
  checkcapacitation: any[] = [];
  nuevoDato: any = {};
  datos: any[] = [];
  mostrarAlgo: boolean = true;
  enviado:boolean = false;

  positiondata:number =-1
  update:boolean = false;



  constructor(private fb: FormBuilder, private user: Usuario, private sanitizer: DomSanitizer,) {
    super();
    localStorage.setItem("datosTrainers", JSON.stringify([]));

    this.trainerForm = this.fb.group({
      firstname: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(20), Validators.pattern("^[A-Za-zñÑáéíóúÁÉÍÓÚ]+$")]],
      secondname: ['',[  Validators.maxLength(20), Validators.pattern("^[A-Za-zñÑáéíóúÁÉÍÓÚ]+$")]],
      surname: ['',[Validators.required, Validators.minLength(1), Validators.maxLength(20), Validators.pattern("^[A-Za-zñÑáéíóúÁÉÍÓÚ]+$")]],
      secondsurname: ['',[  Validators.maxLength(20), Validators.pattern("^[A-Za-zñÑáéíóúÁÉÍÓÚ]+$")]],
      idtype: ['',[Validators.required]],
      idnumber: ['',[Validators.required,Validators.pattern('[0-9]*'),Validators.minLength(1), Validators.maxLength(11)]],
      profession: ['',[Validators.required, Validators.minLength(10), Validators.maxLength(50), Validators.pattern('[a-zA-Z ]*')]],
      professionalnumber: ['',[Validators.required,Validators.minLength(1), Validators.maxLength(20), Validators.pattern("^[0-9a-zA-Z]+$")]],
      telephone: ['',[Validators.required,Validators.minLength(1), Validators.maxLength(10), Validators.pattern("^[0-9]*$")]],
      email: ['',[Validators.required, Validators.email, Validators.maxLength(120),Validators.pattern("^[0-9A-Za-zñÑáéíóúÁÉÍÓÚ_@*+¿¡?'-/.]+$")]],
    });

    this.fileForm = this.fb.group({
      cc: [''],
      cs: [''],
      hv: [''],
      dtc: [''],
      pp: [''],
      dpt: [''],
      tp: [''],
      rdc: [''],
      mi: [''],
    });





  }
  public getErrorMessage(field: string): string {



    let message;
    const onlyLetters: Array<string> = ['firstname', 'secondname', 'surname', 'secondsurname','profession'];
    const onlyNumbers: Array<string> = ['telephone', 'idnumber'];



    if (this.isTouchedField(this.trainerForm,field)) {
      if (this.trainerForm?.get(field).hasError('required')) {
        message = 'Es Requerido';
      } else if (onlyLetters.includes(field)) {

        if (this.trainerForm?.get(field).hasError('pattern')) {
          message = 'Solo se admiten letras';
        } else if (this.trainerForm?.get(field).hasError('maxlength')) {
          message = `Excede el numero de caracteres permitidos`;
        }
        else if (this.trainerForm?.get(field).hasError('minlength')) {
          message = `Debe tener al menos 10 caracteres`;
        }


      } else if (onlyNumbers.includes(field)) {
        if (this.trainerForm?.get(field).hasError('pattern')) {
          message = 'Solo se admiten números';
        } else if (this.trainerForm?.get(field).hasError('minlength')) {
          message = `Debe tener al menos 10 caracteres`;
        } else if (this.trainerForm?.get(field).hasError('maxlength')) {
          message = `Permite hasta 11 caracteres`;
        }

      } else if (this.trainerForm?.get(field).hasError('email')) {
        message = 'No tiene el formato de un email';
      }  else if (this.trainerForm?.get(field).hasError('pattern')) {
        message = `Caracter no permitido ` ;
      }

    }

    return message;
  }

  ngOnInit(): void {
    this.listDni();
    this.listCapacitation();
    this.resetForm();
  }

  resetForm() {
    //this.miForm.reset();

    this.isUpdating = false;
    this.nuevoDato = {};
    this.fileForm.get("cc").setValue(null);
    this.fileForm.get("cs").setValue(null);
    this.fileForm.get("hv").setValue(null);
    this.fileForm.get("dtc").setValue(null);
    this.fileForm.get("pp").setValue(null);
    this.fileForm.get("dpt").setValue(null);
    this.fileForm.get("tp").setValue(null);
    this.fileForm.get("rdc").setValue(null);
    this.fileForm.get("mi").setValue(null);
    this.update=false;
    this.checkcapacitation.forEach(e => {
      e.selected = false;
    });
  }

  change = (e:any) => {
    console.log("cambio");
    console.log(e);
    this.updateFlag = true;
  }

  agregarDato() {


    if(this.isUpdating){

      console.log("Updating data ...");


      if (this.validateForm() && this.trainerForm.valid) {


        const capacitacionIds = this.checkcapacitation
        .filter(opcion => opcion.selected)
        .map(opcion => opcion.idTipoCapacitacion);

        const capacitacionTexts = capacitacionIds.map(id => {
            const capacitacion = this.checkcapacitation.find(opcion => opcion.idTipoCapacitacion === id);
            return capacitacion ? capacitacion.vcDescripcion : '';
          });

        //Validaciones
        //let cantidadDocumentos:number = this.documentsArray.filter((d:any)=> d.document.name != 'Pensum de pregrado').length;
        //console.log('cantidad de documentos: '+ cantidadDocumentos);
        console.log(this.checkcapacitation);
        console.log(capacitacionIds);
        if(capacitacionIds.length != 0){

          let documents:any[] = [];


          console.log("Versión original de todos los documentos");

          console.log(this.datos[this.positiondata]);

          console.log("Documentos a reemplazar");

          console.log(this.documentsArray);

          this.datos[this.positiondata].documents.forEach((element: { document: any; }) => {

            console.log(element);

            this.documentsArray.forEach((element2: { document: { name: any; }; }) => {
              console.log(element2);

              if(element2.document.name==element.document.name){
                console.log("coinciden");
                console.log(element);
                console.log(element2);
                element = element2;
                console.log(element);
              }

            });
            console.log(element);
            documents.push(element);

          });

          console.log("Versión actualizada");

          console.log(this.datos[this.positiondata]);

          console.log(documents);


          let nuevoDatoConTexto = {
            ...this.nuevoDato,
            capacitation: capacitacionIds,
            //capacitacionText: this.getDescriptions(capacitacionIds),
            documents
          };

          console.log(this.datos);
          console.log(nuevoDatoConTexto);

          if(this.update)
          {
            this.datos[this.positiondata]=nuevoDatoConTexto;
            this.update=false;
            this.positiondata=-1;
          }
          else
          {
            console.log("There is something wrong with this");
            //this.datos.push(nuevoDatoConTexto);
          }



          //localStorage.setItem("datosTrainers", JSON.stringify(this.datos));
          //this.datosTrainers.emit(this.datos);

          this.checkcapacitation.forEach(e => {
            e.selected = false;
          });
          this.documentsArray = [];
          this.resetForm();
          $("#exampleModal").modal("hide");
          console.log("datos console log", this.datos);

        }else{

          Swal.fire({
            icon: 'error',
            title: '<h5>Tipos de capacitaciones sin registrar</h5>',
            text: 'Debe registrar al menos un tipo de capacitación.',
            allowOutsideClick: false
          });

        }

      }else{
      console.log(super.getAllErrors(this.trainerForm));
        Swal.fire({
          icon: 'error',
          title: '<h5>campos obligatorios sin registrar</h5>',
          text: 'Debe registrar todos los campos obligatorios.',
          allowOutsideClick: false
        });

      }

    }else{

      this.enviado = true;
      this.isUpdating = false;

      console.log(this.validateForm())
      console.log(this.trainerForm)
      if (this.validateForm() && this.trainerForm.valid) {


        const capacitacionIds = this.checkcapacitation
        .filter(opcion => opcion.selected)
        .map(opcion => opcion.idTipoCapacitacion);

        const capacitacionTexts = capacitacionIds.map(id => {
            const capacitacion = this.checkcapacitation.find(opcion => opcion.idTipoCapacitacion === id);
            return capacitacion ? capacitacion.vcDescripcion : '';
          });

        //Validaciones
        let cantidadDocumentos:number = this.documentsArray.filter((d:any)=> d.document.name != 'Pensum de pregrado').length;
        console.log('cantidad de documentos: '+ cantidadDocumentos);
        console.log(this.checkcapacitation);
        console.log(capacitacionIds);
        if(capacitacionIds.length != 0){

          if (cantidadDocumentos == 8) {

            let nuevoDatoConTexto = {
              ...this.nuevoDato,
              capacitation: capacitacionIds,
              //capacitacionText: this.getDescriptions(capacitacionIds),
              documents: clone(this.documentsArray)
            };

            console.log(this.datos);
            console.log(nuevoDatoConTexto);

            if(this.update)
            {
              this.datos[this.positiondata]=nuevoDatoConTexto;
              this.update=false;
              this.positiondata=-1;
            }
            else
            {
              this.datos.push(nuevoDatoConTexto);
            }


            localStorage.setItem("datosTrainers", JSON.stringify(this.datos));
            this.datosTrainers.emit(this.datos);

            this.checkcapacitation.forEach(e => {
              e.selected = false;
            });
            this.documentsArray = [];
            this.resetForm();
            $("#exampleModal").modal("hide");
            console.log("datos console log", this.datos)

          }else{
            Swal.fire({
              icon: 'error',
              title: '<h5>Documentos obligatorios incompletos</h5>',
              text: 'Faltan documentos obligatorios por cargar.',
              allowOutsideClick: false
            });
          }

        }else{

          Swal.fire({
            icon: 'error',
            title: '<h5>Tipos de capacitaciones sin registrar</h5>',
            text: 'Debe registrar al menos un tipo de capacitación.',
            allowOutsideClick: false
          });

        }

      }else{
      console.log(super.getAllErrors(this.trainerForm));
        Swal.fire({
          icon: 'error',
          title: '<h5>campos obligatorios sin registrar</h5>',
          text: 'Debe registrar todos los campos obligatorios.',
          allowOutsideClick: false
        });

      }

    }
  }

  getDescriptions(e: any[]) {
    let text: string = '';

    e.forEach((id, index) => {
      const capacitacion = this.checkcapacitation.find(opcion => opcion.idTipoCapacitacion === id);
      if (capacitacion) {
        text += capacitacion.vcDescripcion;
        if (index < e.length - 1) {
          text += ', '; // Agregar coma si no es el último elemento
        }
      }
    });

    return text;
  }


  eliminarElemento(index: number) {
    this.datos.splice(index, 1);
    console.log(this.datos);
    this.datosTrainers.emit(this.datos);
  }

  listDni() {
    this.user.getTypeDni('bTipoIdentificacion').subscribe((values) => {
      this.selectdni = values.data;
    });
  }

  listCapacitation() {
    this.user.getTiposCapacitacion().subscribe((response) => {
      console.log(response.data);

      for (let i = 0; i < response.data.length; i++) {
        this.checkcapacitation.push(
          {
            idTipoCapacitacion:response.data[i].idTipoCapacitacion,
            vcDescripcion:response.data[i].vcDescripcion,
            selected:false
          }
        )

      }
    });
  }
  changecapacitacion(e:any) {

    console.log(e)


    this.checkcapacitation.find((item, index, array) => {
      console.log(item)
      if (item.idTipoCapacitacion === e) {
        //array[index].selected = !array[index].selected;
      }
    });
    console.log(this.checkcapacitation);

  }




  onChange = (e:any, d:any) => {
    //console.log("este es un evento");
    //console.log(e);
    //console.log(e.file.target.files[0]);
    if(e.file.target.files.length>0)
    { let posicion=-1;
      for (let i = 0; i <this.documentsArray.length ; i++) {
        if(this.documentsArray[i].document.id ==e.document.id)
        {
          posicion=i;

          this.documentsArray[i]={file: e.file.target.files[0], document: e.document}
          break;
        }

      }
      if(posicion==-1 || this.documentsArray.length==0)
      {
        this.documentsArray.push({file: e.file.target.files[0], document: e.document});
        this.documentsArrayPosition.push(d);
      }
      //console.log(this.documentsArray);
    }
    else {
      let p = this.documentsArrayPosition.indexOf(d);
      this.documentsArray.splice(p,1);
      this.documentsArrayPosition.splice(p,1);
    }
  }

  seeDocument = (e:any, re:any) => {

    console.log(e);
    console.log(re);
    if(re != null){

      //console.log(re);

      //console.log(e.files[0].name)
      //let name = e.files[0].file.name;
      //let size = e.files[0].file.size;

      let data;
      if(typeof re === "string"){
        if (e.files.length==0) {
          Swal.fire({
            icon: 'error',
            title: 'Sin archivo',
            text: 'No se ha cargado un archivo',
          })
        }else{
          data = window.URL.createObjectURL(e.files[0]);
        }
      }else{

        //console.log(this.obj);

        /*
        this.documents.forEach(element => {

          if(element.)

        });
        */

        data = window.URL.createObjectURL(re);
      }

      var link = document.createElement('a');
      link.href = data;
      link.target = "_blank";
      link.click();
    }else{
      if(e.files.length==0){
        Swal.fire({
          icon: 'error',
          title: 'Sin archivo',
          text: 'No se ha cargado un archivo',
        })
      }else{
        if (e.files[0].type != 'application/pdf') {

          Swal.fire({
            icon: 'error',
            title: 'Tipo de archivo incorrecto',
            text: 'El archivo cargado no es un pdf',
          })

        } else {
        const data = window.URL.createObjectURL(e.files[0]);
        var link = document.createElement('a');
        link.href = data;
        link.target = "_blank";
        link.click();
        }
      }
    }

  }

  delete = (e:any) =>{
    console.log("eliminando");

    let p = this.documentsArrayPosition.indexOf(e);
    this.documentsArray.splice(p,1);
    this.documentsArrayPosition.splice(p,1);

    console.log(this.documentsArray);
  }

  closeDocument = () => {
    this.viewDocument = false;
  }

  validateForm = () => {
    let bandera =  (this.nuevoDato.firstname &&
    this.nuevoDato.flastname &&
    this.nuevoDato.stypeidentity &&
    this.nuevoDato.document &&
    this.nuevoDato.title &&
    this.nuevoDato.numbertitle &&
    this.nuevoDato.contact &&
    this.nuevoDato.email)?true:false;
    return bandera;
  }

  updateTrainerButton = (e:any,index:any) => {
    this.updateFlag = false;
    this.isUpdating = true;
    console.log(this.checkcapacitation);
    e.capacitation.forEach((e2:any) => {
      this.checkcapacitation.forEach((element:any) => {

        if (element.idTipoCapacitacion==e2) {

          console.log("coinciden");
          element.selected = !element.selected;
        }
      });
    });
    console.log(this.checkcapacitation)

    console.log(e)
    this.updateTrainer.emit(e);
    this.nuevoDato.firstname = e.firstname;
    this.nuevoDato.secondname = e.secondname;
    this.nuevoDato.flastname = e.flastname;
    this.nuevoDato.slastname = e.slastname;
    this.nuevoDato.stypeidentity = e.stypeidentity;
    this.nuevoDato.document = e.document;
    this.nuevoDato.title = e.title;
    this.nuevoDato.numbertitle = e.numbertitle;
    this.nuevoDato.contact = e.contact;
    this.nuevoDato.email = e.email;
    this.update=true;
    this.positiondata=index;

    e.documents.forEach((element: { document: { initials: string }; file: any }) => {
      this.nameFile[element.document.initials] =  element.file.name;
      this.sizeFile[element.document.initials] = element.file.size;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        console.log(e.target.result);
        console.log(new Blob([new Uint8Array(e.target.result)], {type: "application/pdf"}));
        let aux:string = element.document.initials;
        this.fileForm.get(aux).patchValue(new Blob([new Uint8Array(e.target.result)], {type: "application/pdf"}));
      };
      reader.readAsArrayBuffer(element.file);
    });

    console.log(this.nameFile);

    console.log(this.datos);

    console.log(e);

    console.log(e.documents[0].file);

    console.log(e.documents);

    //this.fileForm.get("cc").setValue(e.documents[0].file);

    /*

    */
    //this.nuevoDato.capacitation = e.capacitation;
  }
}
