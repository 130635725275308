<div>
  <div class="d-flex justify-content-end mr-5">
    <button type="button" class="button btn-primary" (click)="reports()">REPORTES</button>
  </div>

  <div class="tabs-govco">
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <a (click)="inManagement()" class="nav-item nav-link" [ngClass]="{'active':!getTab(flagTap)}" id="nav-home-tab" data-toggle="tab"
           href="#enGestion" role="tab" aria-controls="nav-home" [attr.aria-selected]="!getTab(flagTap)">
          En gestión
        </a>
        <a (click)="inTrainingsFollowUp()" class="nav-item nav-link" [ngClass]="{'active':getTab(flagTap)}" id="nav-profile-tab" data-toggle="tab"
           href="#seguimientoCapacitaciones" role="tab" aria-controls="nav-profile" [attr.aria-selected]="getTab(flagTap)">
          Seguimiento a Capacitaciones
        </a>
      </div>
    </nav>

    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade" [ngClass]="{'show':!getTab(flagTap), 'active':!getTab(flagTap)}" id="enGestion" role="tabpanel" aria-labelledby="nav-home-tab">
        <div class="filtros mb-5">
          <form [formGroup]="selectOptionFilterManagementForm">
            <div class="form-row ml-5" style="margin-left: 20px;">
              <div class="form-group col-sm-12 col-md-3 col-xs-12 col-xl-3 col-lg-3 ">
                <Label class="d-block">Filtrar por</Label>
                <select formControlName="selectOptionFilterInputManagement" style="height: 35px;" class="form-select"
                        aria-label="Seleccione" (change)="changestatus()">
                  <option value="0" hidden>Seleccione una opción</option>
                  <option value="1">ID solicitud</option>
                  <option value="2">Solicitante</option>
                  <option value="3">No. identificación</option>
                  <option value="4">Tipo de solicitud</option>
                  <option value="5">Tipo de solicitante</option>
                  <option value="6">Fecha de solicitud</option>
                  <option value="7">Estado de la solicitud</option>
                </select>
              </div>
              <div class="form-group col-sm-12 col-md-3 col-xs-12 col-xl-3 col-lg-3 " *ngIf="searchbar">
                <input formControlName="valueFilterInputManagement"
                       class="form-control" type="text" placeholder="Ingrese su búsqueda" style="margin-top: 30px;">
              </div>
              <div class="form-group col-sm-12 col-md-3 col-xs-12 col-xl-3 col-lg-3 " *ngIf="datebar">
                <input formControlName="fecha" class="form-control search_input_icon" type="date"
                       style="margin-top: 30px;"/>
              </div>
              <div class="form-group col-sm-12 col-md-3 col-xs-12 col-xl-3 col-lg-3 ">
                <button class="btn btn-round btn-middle mx-3"
                        style="margin-top:30px !important;padding: 10px 10px;width: 12%; color: white; font-size: 17px;padding:5px 40px;border: 1px solid #3366cc;"
                        [disabled]="selectOptionFilterManagementForm.get('selectOptionFilterInputManagement').value==0 || selectOptionFilterManagementForm.get('valueFilterInputManagement').status == 'INVALID'"
                        (click)="filterManagement()" type="button">filtrar
                </button>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-lg-10 col-md-10 col-xl-10 col-sm-12 col-xs-12">
                <div *ngIf="selectOptionFilterManagementForm.get('valueFilterInputManagement').status=='INVALID'"
                     class="alert mt-2"
                     style="background-color: #e6effd;color: #004884;font-size: 15px; height: 100px;">
                  <p>* Solo se admiten caracteres alfanuméricos, a excepción del caracter / y el . no ingrese caracteres
                    especiales.</p>
                  <p>* La cantidad máxima de caracteres admitidos es 50.</p>
                </div>
              </div>
            </div>
          </form>
        </div>
        <nz-spin style="display: block;width: 100%;" [nzSpinning]="isLoading" [nzSize]="'large'"
                 nzTip="Se estan cargando los datos, por favor espere un momento....">
          <app-procedure-dashboard [data]="dataFilterTableManagement" [structure]="structureTableManagement"
                                   (linkEvent)="redirectToViewDetail($event)"></app-procedure-dashboard>
        </nz-spin>
      </div>
      <div class="tab-pane fade" [ngClass]="{'show':getTab(flagTap), 'active':getTab(flagTap)}" id="seguimientoCapacitaciones" role="tabpanel" aria-labelledby="nav-profile-tab">
        <div class="filtros mb-5">
          <form [formGroup]="selectOptionFilterTrainingsFollowUpForm">
              <div class="form-row ml-5" style="margin-left: 20px;">
                <div class="form-group col-sm-12 col-md-3 col-xs-12 col-xl-3 col-lg-3 ">
                  <Label class="d-block">Filtrar por</Label>
                  <select formControlName="selectOptionFilterInputTrainingsFollowUp" style="height: 35px;"
                          class="form-select" aria-label="Seleccione" (change)="changestatuscapacitation()">
                    <option value="0" hidden>Seleccione una opción</option>
                    <option value="1">Número de resolución</option>
                    <option value="2">Nombre del ciudadano/entidad</option>
                    <option value="3">Identificación</option>
                    <option value="4">Fecha de resolución</option>
                    <option value="5">Estado</option>
                  </select>
                </div>
                <div class="form-group col-sm-12 col-md-3 col-xs-12 col-xl-3 col-lg-3 "  *ngIf="searchbarcapacitation">
                  <input formControlName="valueFilterInputTrainingsFollowUp" class="form-control search_input_icon"
                         type="text" placeholder="Ingrese su búsqueda" style="margin-top: 30px;"/>
                </div>
                <div class="form-group col-sm-12 col-md-3 col-xs-12 col-xl-3 col-lg-3 " *ngIf="datebarcapacitation">
                  <input formControlName="fechacapacitation" class="form-control search_input_icon" type="date" style="margin-top: 30px;"/>
                </div>
                <div class="form-group col-sm-12 col-md-3 col-xs-12 col-xl-3 col-lg-3 "  *ngIf="statusbarcapacitation">
                  <select formControlName="estadocapacitation" style="height: 35px;" class="form-select"
                          aria-label="Seleccione" style="margin-top: 30px;">
                    <option value="0" hidden>Seleccione una opción</option>
                    <option value="Activa">Activa</option>
                    <option value="Inactiva">Inactiva</option>
                  </select>
                </div>
                <div class="form-group col-sm-12 col-md-3 col-xs-12 col-xl-3 col-lg-3 ">
                  <button class="btn btn-round btn-middle mx-3"
                          style="margin-top:30px !important;padding: 10px 10px;width: 12%; color: white; font-size: 17px;padding:5px 40px;border: 1px solid #3366cc;"
                          [disabled]="selectOptionFilterTrainingsFollowUpForm.get('selectOptionFilterInputTrainingsFollowUp').value==0 "
                          (click)="filterTrainingsFollowUp()" type="button">
                    FILTRAR
                  </button>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-lg-12">
                  <div
                    *ngIf="selectOptionFilterTrainingsFollowUpForm.get('valueFilterInputTrainingsFollowUp').status=='INVALID'"
                    class="alert mt-2"
                    style="background-color: #e6effd; color: #004884; font-size: 15px; height: 100px;">
                    <p>* Solo se admiten caracteres alfanuméricos, a excepción del caracter / no ingrese caracteres
                      especiales.</p>
                    <p>* La cantidad máxima de caracteres admitidos es 50.</p>
                  </div>
                </div>
              </div>

          </form>
        </div>
        <app-procedure-dashboard [data]="dataFilterTableTrainingsFollowUp" [structure]="structureTableTrainingsFollowUp"
                                 (linkEvent)="viewTraining($event)"
                                 (documentEvent)="viewApprovalDocument($event)"></app-procedure-dashboard>
      </div>
    </div>
  </div>

  <div class="documentViewer">
    <app-document-viewer [isVisible]="viewDocument" [document]="urlSafeDocument"
                         (closeButtonEvent)="closeDocument()"></app-document-viewer>
  </div>
</div>
