import { dataGenerarRecursoDto } from './../../../../core/models/dataGenerarRecursoDto';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RequestService } from '@core-app/services/request.service';
import Swal from 'sweetalert2';
import {ROUTES} from "@core-app/enums";
import {DocumentsService} from "@core-app/services/documents.service";
import {DomSanitizer} from "@angular/platform-browser";
import {Router} from "@angular/router";

@Component({
  selector: 'app-request-search',
  templateUrl: './request-search.component.html',
  styleUrls: ['./request-search.component.scss'],
})
export class RequestSearchComponent implements OnInit {

  viewResource: boolean = false;

  fileResource:any;

  isVisibleResolution:boolean = false;

  isCanceled:boolean = false;

  oid: any = "";

  idSol:any = 0;

  path:any = "";

  solicitudes: any[] = [];
  tbodyVisible = false;
  searchText: string = '';
  thereAreResults:boolean = true;
  firsttime : boolean=true;
  showGenerateResourceLink: boolean = false;
  show:boolean=false;
  public selectOptionFilterManagementForm: FormGroup;

  /**
   * Lista de opciones de la linea de avance
   */
  advanceLineData = [
    {
      name: 'Inicio',
      link: 'aut-cap/'+ROUTES.Request+"/"+ROUTES.Menu
    },
    {
      name: 'Hago mi solicitud',
      link: 'aut-cap/'+ROUTES.Request+"/"+ROUTES.REQUEST_CITIZEN
    },
    {
      name: 'Procesan mi solicitud',
      link: ''
    },
    {
      name: 'Respuesta',
      link: 'aut-cap/'+ROUTES.Request+"/"+ROUTES.SearchRequest
    }
  ]

  public searchbar:boolean=true;
  public datebar:boolean=false;

  dataTableManagement: any = [];

  dataFilterTableManagement: any = [];

  public structureTableManagement: any= [
    {
      name: 'Id solicitud',
      type: 'texto',
      value: 'vcRadicado'
    },
    {
      name: 'Nombre del trámite',
      type: 'texto',
      value: 'vcNombreTramite'
    },
    {
      name: 'Fecha de solicitud',
      type: 'texto',
      value: 'dtFechaSolicitud'
    },
   {
      name: 'Estado de la solicitud',
      type: 'texto',
      value: 'vcEstado'
    },
    {
      name: 'Acciones permitidas',
      type: 'enlaceMultiple',
      state: 'vcEstado',
      links: [
        {
          name: 'Descargar Resolución',
          states: [
            'Aprobado',
            'Negado',
            'Cancelado',
            'Anulado',
            'Cancelado por incumplimiento'
          ]
        },
        {
          name: 'Generar recurso',
          states: [
            'Aprobado',
            'Cancelado',
            'Negado',
            'Anulado',
            'Cancelado por incumplimiento'
          ]
        },
        {
          name: 'Subsanar',
          states: [
            'En Subsanación'
          ]
        },
        {
          name: 'Ver detalle',
          states: [
            'En Revisión',
            'En Verificación',
            'Devuelta por Subdirector',
            'Para firma',

          ]
        },
      ]
    },
    {
      name: 'Tiempo de atención restante',
      type: 'remainingDays2',
      value1: 'diasRestantes',
      value2: 'diasHabiles',
      color1: '#e6effd'
    }
  ];

  constructor(
    private requestService: RequestService,
    private fb: FormBuilder,
    private documentService:DocumentsService, public sanitizer: DomSanitizer,private router: Router
  ) {



    this.selectOptionFilterManagementForm = this.fb.group({
      selectOptionFilterInputManagement: ['0'],
      valueFilterInputManagement: ['', [Validators.pattern('[a-zA-Z0-9/ó ]*'), Validators.maxLength(20)]],
      selectOptionFilterGeneralInputManagement: ['0'],
      valueFilterGeneralInputManagement: ['', [Validators.pattern('[a-zA-Z0-9]*'), Validators.maxLength(20)]],
      fecha:[]

    });

  }

  ngOnInit(): void {
    this.getRequest();
  }

  changestatus(){
    if(this.selectOptionFilterManagementForm.get('selectOptionFilterInputManagement').value=="3")
    {
      this.datebar=true;
      this.searchbar =false;
    }
    else {
      this.datebar=false;
      this.searchbar =true;
    }
  }
  getRequest() {

  }

  search = () => {
    this.firsttime=false;
    const idUser = JSON.parse(localStorage.getItem('currentUser')).userId;

    this.requestService.GetAllRequestsCitizen(idUser).subscribe((result) => {
      console.log("data", result);
      let isNatural: boolean = JSON.parse(localStorage.getItem("userData")).numeroIdentificacionRL == null;
      console.log(isNatural ? 'natural' : 'juridica');

      if (result.data != null) {
        console.log("entre");
        result.data.forEach((e: any) => {

          let date = new Date();

          if(e.vcEstado == 'En Subsanación' && e.dtFechaRevision != null ){
            const [day, month, year] = e.dtFechaRevision.split('/');
            date = new Date(year + "-" + month + "-" + day);
            console.log(date);
          }else{
            const [day, month, year] = e.dtFechaSolicitud.split('/');
            date = new Date(year + "-" + month + "-" + day);
          }

          
          
          date.setDate(date.getDate() + 1);
          let fecha = date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, '0') + "-" + date.getDate().toString().padStart(2, '0');
          this.requestService.getRemainingDays(e.vcEstado == 'En Subsanación'?'2CB35A45-12D8-4AAB-8A01-5DC5FDDAC592':'2CB35A45-12D8-4AAB-8A01-5DC5FDDAC591', fecha).subscribe((rd) => {
            console.log(rd);
            e.diasRestantes = isNatural ? rd.data.personaNatural.diasRestantes : rd.data.personaJuridica.diasRestantes;
            e.diasHabiles = isNatural ? rd.data.personaNatural.totalANS : rd.data.personaJuridica.totalANS;
          });
        });


        if (this.selectOptionFilterManagementForm.get('selectOptionFilterGeneralInputManagement').value == 1) {
          this.dataTableManagement = result.data;
          this.dataFilterTableManagement = this.dataTableManagement;
          this.thereAreResults = true;
        } else {
          const data = result.data.filter((d: { vcRadicado: any; }) => d.vcRadicado == this.selectOptionFilterManagementForm.get('valueFilterGeneralInputManagement').value);

          if (data.length == 0) {
            this.thereAreResults = false;
          } else {
            this.dataTableManagement = data;
            this.dataFilterTableManagement = this.dataTableManagement;
            this.thereAreResults = true;
          }
        }
      } else {
        this.thereAreResults = false;
      }
    });
  }







  allowedActionts = (e:any) => {
    console.log("hello world 1");
    console.log("sip");
    console.log(e);

    if(e.option.name == "Generar recurso"){
      console.log(e.record.idSolcitud);
      this.viewResource = true;
      this.idSol = e.record.idSolcitud;
      this.oid = e.record.vcRadicado;
    }
    if(e.option.name == "Descargar Resolución")
    {
      if(e.record.vcPath!="")
      {
        this.oid = e.record.vcRadicado;

        if(e.record.vcEstado=="Cancelado por incumplimiento" || e.record.vcEstado=="Cancelado"){

        this.path = e.record.vcPath;
        this.isVisibleResolution = true;
        this.isCanceled = e.record.vcEstado=="Cancelado";


        }else{
          const contenedor = "manipalimentos";
          const oid = e.record.vcRadicado;
            //"2023RERMA000002421";
          let path = e.record.vcPath;
          console.log(oid,path);
          //"1094955221_Tarjeta_profesional.pdf"
          this.documentService.GetBlob(contenedor, oid, path).subscribe(resp => {
            const data = window.URL.createObjectURL(resp.body);
            var link = document.createElement('a');
            link.href = data;
            link.download = oid+".pdf";
            link.click();

          });
        }

      }

    }


    if(e.option.name == "Subsanar")
    {
      const idSolicitud = e.record.idSolcitud;
      localStorage.setItem('idSolicitud', idSolicitud.toString());
      localStorage.setItem('tipo','subsanacion');
      this.router.navigateByUrl(`${ROUTES.MainPage}/${ROUTES.Request}/${ROUTES.User_Request}`);

    }
    if(e.option.name == "Ver detalle")
    {
      const idSolicitud = e.record.idSolcitud;
      localStorage.setItem('idSolicitud', idSolicitud.toString());
      localStorage.setItem('tipo','ver');
      this.router.navigateByUrl(`${ROUTES.MainPage}/${ROUTES.Request}/${ROUTES.User_Request}`);

    }


  }

  /**
   * Fitro de pestaña de gestión
   */

  filterManagement(){
    console.log('Cantidad de datos: '+this.dataFilterTableManagement.length);
    console.log(this.dataFilterTableManagement);
    switch (this.selectOptionFilterManagementForm.get('selectOptionFilterInputManagement').value) {
      case '1':
      this.dataFilterTableManagement = this.dataTableManagement.filter((d: { vcRadicado: any; }) => d.vcRadicado.includes(this.selectOptionFilterManagementForm.get('valueFilterInputManagement').value));
      break;
      case '2':
        this.dataFilterTableManagement = this.dataTableManagement.filter((d: { vcEstado: any; }) => d.vcEstado .includes(this.selectOptionFilterManagementForm.get('valueFilterInputManagement').value));
      break;
      case '3':
        let fecha="";
        if(this.selectOptionFilterManagementForm.get('fecha').value!=null)
        {
          fecha=this.selectOptionFilterManagementForm.get('fecha').value.toString().split('-')
          fecha=fecha[2]+"/"+fecha[1]+"/"+fecha[0]
        }

        this.dataFilterTableManagement = this.dataTableManagement.filter((d: { dtFechaSolicitud: any; }) => d.dtFechaSolicitud == fecha);
      break;
      default:
        break;
    }
    console.log('Resultados filtrados');
    console.log('Cantidad de datos filtrados: '+this.dataFilterTableManagement.length);
    console.log(this.dataFilterTableManagement);
  }

  inManagement = () =>{
    this.selectOptionFilterManagementForm.get('selectOptionFilterInputManagement').setValue(0);
    this.dataFilterTableManagement = this.dataTableManagement;
  }

  closeButton = () => {
    this.viewResource = false;
    this.isVisibleResolution = false;
  }

  acceptButton = () => {

    this.viewResource = false;

    console.log(this.fileResource.type);

    if(this.fileResource.type == 'application/pdf'){

      if((this.fileResource.size/1048576)<=3){

        const idUser = JSON.parse(localStorage.getItem('currentUser')).userId;

            console.log(this.idSol);
            console.log(idUser);

            const formData = new FormData();
            let file: File = this.fileResource;
            const reader = new FileReader();
            reader.onload = (e: any) => {

              console.log(e.target.result);
              formData.append('NameFile', idUser+"_Recurso.pdf");
              formData.append('ContainerName', "manipalimentos");
              formData.append('Oid', this.oid);
              formData.append("File", new Blob([new Uint8Array(e.target.result)], {type: "application/pdf"}));
              this.documentService.addBlob(formData).subscribe((respuesta: any) => {
                console.log(respuesta);

                let dataRecurso:dataGenerarRecursoDto = {
                  idDocumento: 0,
                  solicitudId: this.idSol,
                  usuarioId: idUser,
                  tipoDocumentoId: 45,
                  vcNombreDocumento: "Recurso",
                  vcPath: idUser+"_Recurso.pdf"
                }
                this.requestService.GenerarRecurso(dataRecurso).subscribe(resp => {
                  console.log("This is the result:")
                  console.log(resp);
                  
                  if (resp.message == "OK") {

                    Swal.fire({
                      title: '<h5>Recurso generado</h5>',
                      text: 'Se ha generado el recurso de reposición de manera exitosa.',
                      showDenyButton: false,
                      showCancelButton: false,
                      confirmButtonText: "Aceptar",
                      allowOutsideClick: false
                    }).then((result) => {
                      /* Read more about isConfirmed, isDenied below */
                      if (result.isConfirmed) {
                        window.location.reload();
                      }
                    });
                  }else{
                    Swal.fire({
                      icon: 'error',
                      title: '<h5>Falla al generar recurso</h5>',
                      text: 'Se presento un error al generar el recurso de reposición.',
                      allowOutsideClick: false
                    });
                  }
                  
                  
                })
              })
            };
            reader.readAsArrayBuffer(file);
        
      }else{
        Swal.fire({
          icon: 'error',
          title: '<h5>Tamaño de documento incorrecto</h5>',
          text: 'El tamaño maximo del archivo debe es 3 MB.',
          allowOutsideClick: false
        });
      }
    }else{
      Swal.fire({
        icon: 'error',
        title: '<h5>Tipo de documento incorrecto</h5>',
        text: 'Debe adjuntar un archivo de tipo pdf.',
        allowOutsideClick: false
      });
    }


  }

  onChange = (e:any) => {

    if(e.file.target.files.length>0)
    {
      this.fileResource = e.file.target.files[0];

    }
    else {
      this.fileResource = null;
    }
    console.log(this.fileResource);
  }

  downloadResolution = (flag:boolean) => {
      const contenedor = "manipalimentos";
      const oid = this.oid;
        //"2023RERMA000002421";
      let path = this.path;
      if(flag){
        path = path.replace("Resolución_de_aprobación", "Resolución_de_cancelación");
      }
      console.log(oid,path);
      //"1094955221_Tarjeta_profesional.pdf"
      this.documentService.GetBlob(contenedor, oid, path).subscribe(resp => {
        const data = window.URL.createObjectURL(resp.body);
        var link = document.createElement('a');
        link.href = data;
        link.download = oid+".pdf";
        link.click();
        this.isVisibleResolution = false;
      })
  }

}
