<div class="header-document row">
  <div class="top-content-document w-100">
    <div *ngIf="documentation">

      <ng-container *ngIf="!viewfinder; then notviewfindertp; else viewfindertp"></ng-container>

      <ng-template #notviewfindertp>
        <table>
          <tr>
            <th class="text-center body-1-gov-co" *ngIf="!documentsdisabled"><strong class="adjuntos">Descripción</strong></th>
            <th class="text-center body-1-gov-co" *ngIf="!documentsdisabled "><strong class="adjuntos">Ver</strong></th>
            <th class="text-center body-1-gov-co " *ngIf="!documentsdisabled"><strong class="adjuntos">¿Cumple?</strong></th>
          </tr>
          <tr class="space" *ngFor="let d of documentation; let i = index">
            <td class="text-right body-2-gov-co adjuntos">{{d.mandatory?'*':''}}{{d.descripcion}}</td>
            <td *ngIf="!subsanacion"> <span (click)="verDocumento(d.path, d.fileNumber)"
                class="govco-icon govco-icon-facebook-cp"><i class="bi bi-file-earmark-pdf icon"></i></span></td>
            <td *ngIf="!documentsdisabled">
              <div>
                <div class="p-3 d-inline-block check">
                  <input [disabled]="flag" (click)="approvedDocumentation(d, 'si',null)" [checked]="d.aprovado"
                    value="si" type="radio" name="approvedDocumentation{{i}}">
                  <label class="adjuntos">Si</label>
                </div>
                <div class="p-3 ms-2 my-4 d-inline-block check">
                  <input [disabled]="flag" (click)="approvedDocumentation(d, 'no',null)" [checked]="!d.aprovado"
                    value="no" type="radio" name="approvedDocumentation{{i}}">
                  <label class="adjuntos">No</label>
                </div>
              </div>
            </td>
            <td class="text-right body-2-gov-co" *ngIf="!d.aprovado && subsanacion">
              <div class="mt-3 row col-12 ">
                <div class="mt-3 col-12 ">
                  <span class="d-block"><b>{{d.mandatory ? '*' : null}}{{d.name}}</b></span>
                  <input accept=".pdf" style="height: 35px;width: 100%;"
                    (change)="approvedDocumentation(d,'a',{file:$event,document:d} )"
                    class="form-control gov-co-form-control d-block" id="formFileLg" type="file" #replenishmentDocument>
                </div>
                <div class="col-4" (click)="seeDocument(replenishmentDocument)">
                  <i class="p-1 bi bi-file-earmark-pdf icon"></i>
                </div>

                <div class="col-4 ml-3 mt-3" style="cursor: pointer;">
                  <span class="d-block">{{replenishmentDocument.files.length == 0 ? 'no file'
                    : replenishmentDocument.files[0].name}}</span>
                  <span class="d-block">{{replenishmentDocument.files.length == 0 ? '0 Kb'
                    : replenishmentDocument.files[0].size / 1000 + ' Kb'}}</span>
                </div>
                <div (click)="replenishmentDocument.value = null; approvedDocumentation(d,'delete',null)">
                  <i class="col-4 mt-4  bi bi-trash3-fill icon"></i>
                </div>

              </div>
            </td>
          </tr>
        </table>
      </ng-template>

      <ng-template #viewfindertp>
        <table style="border-spacing: 10px;
    border-collapse: separate;">
          <tr>
            <th class="text-center body-1-gov-co"><strong>Descripción</strong></th>
            <th class="text-center body-1-gov-co"><strong>Ver</strong></th>
          </tr>
          <tr class="space" *ngFor="let d of documentation; let i = index">
            <td class="text-right body-2-gov-co">{{d.mandatory?'*':''}}{{d.descripcion}}</td>
            <td><span (click)="verDocumento(d.path, d.fileNumber)" class="material-symbols-outlined icon">draft</span></td>
          </tr>
        </table>
      </ng-template>


    </div>
    <div class="text-center">
      <div class="w-100 h-100">
        <iframe *ngIf="mostrarIframe" [src]="urlSafe" class="w-100 h-100" frameBorder='0' scrolling='auto'></iframe>
      </div>
    </div>
  </div>

</div>
